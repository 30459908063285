@import "../../assets/scss/common.scss";

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.container {
    position: relative;
    display: flex;
    height: 100vh;
    flex-direction: column;
    font-family: $amanin_font_family;
    color: white;

    background: linear-gradient(30deg,
            rgb(42, 109, 218),
            rgb(99, 135, 154),
            #2a87a1,
            #92a7de);
    z-index: 0;

    &_mobilewarn {
        display: flex;
        height: 100vh;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 1.2rem;
        font-weight: bold;
        padding:10px;
        color: white;
    }

    & .circle {
        & .one {
            // border:2px solid blue;
            // background-color: white;
            background: rgba(255, 255, 255, 0.04);
            position: absolute;
            top: 0px;
            width: 100vw;
            height: 100vw;
            border-radius: 100% 0 0 0;
            backdrop-filter: blur(3px);
            right: 00px;
            z-index: 0;
        }

        & .two {
            // border:2px solid blue;
            background: rgba(255, 255, 255, 0.04);
            position: absolute;
            width: 100vw;
            height: 100vw;
            border-radius: 0 100% 0 0;
            backdrop-filter: blur(3px);
            left: 00px;
            top: 50%;
            z-index: 0;
        }
    }

    &_bg {
        // background-color: rgba($color: $amanin_logo_color, $alpha: 0.2);
        height: 100vh;
        width: 100%;
        position: absolute;
        z-index: -1;

        &>div {
            // width: 100px;
            // height: 100px;
            // border:1px solid rgba($color: green, $alpha: 0.1);
            // background-color: rgba($color: green, $alpha: 0.1);
            // border:1px solid white;
            position: absolute;
        }
    }

    &_loading {
        width: 200px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // border:1px solid red;
        // background-color: rgba(0, 0, 0, 0.8);
        background-color: white;
        color: black;
        border-radius: 5px;
        animation: fadeIn 1.0s;
        z-index: 102;

        &>span {
            margin: 10px;
            font-family: $amanin_font_family;
            // color: white;
            // font-size: 1.3rem;
        }
    }

    &_modal {
        position: absolute;
        // border: 2px solid green;
        height: 100vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 101;

        &_content {
            width: 400px;
            min-height: 250px;
            border: 1px solid #545454;
            background-color: white;
            border-radius: 10px;
            padding: 10px;
            box-shadow: 3px 3px 7px 3px rgba(0, 0, 0, 0.5);
            animation: fadeIn 1.0s;
        }
    }

    &_top {
        width: 100%;
        max-width: 1500px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 10;

        &_left {
            & .logo {
                display: flex;
                top: 0px;
                padding: 20px 0px 0px 20px;

                &>img {
                    // position: absolute;
                    margin-left: 30px;
                    margin-right: 20px;
                }

                &>span {
                    display: flex;
                    justify-content: center;
                    // border:1px solid red;
                    // font-size: 1.2rem;
                    font-weight: 500;
                    align-items: center;
                    color: #6e7071;
                }
            }
        }

        &_right {
            display: flex;
            align-items: center;
            z-index: 10;
            // border:2px solid red;
            margin-right: 20px;
            & .login {
                // font-size: .8rem;
                font-weight: 300;
            }
        }
    }


    &_bottom {
        z-index: 5;
        display: flex;
        // border: 2px solid red;
        padding-left: 10px;
        // border: 2px solid white;
        height: calc(100% - 84px);
        justify-content: space-around;
        align-items: center;
        width: 100%;
        max-width: 1500px !important;
        margin: 0 auto;
        // margin-top: 30px;

        &_l {

            & .title {
                display: flex;
                flex-direction: column;
                font-weight: 500;
                font-size: 2.6rem;

                @include responsiveDesktopStandard() {
                    font-size: 2.6rem;

                }
                @include responsiveDesktopMedium() {
                    font-size: 2.0rem;
                    
                }
                @include responsiveDesktopSmall() {
                    font-size: 1.7rem;

                }

                @include responsiveMobile() {
                    font-size: 1.5rem;
                }
            }

            & .title2 {
                // border:1px solid red;
                padding: 30px 0px 30px 0;
                font-size: 1.1rem;
                font-weight: 350;
                max-width: 700px;
                // color: #575656;
            }

            & .button {
                // border: 2px solid green;
                position: relative;
                display: flex;
                margin-top: 30px;
                font-size: 1.0rem;
                font-weight: 300;
                min-height: 50px;
                color: white;

                & .btn {
                    padding: 13px;
                    font-size: .9rem;
                    font-weight: 200;
                    margin-right: 20px;
                    box-shadow: 1px 1px 10px rgba($color: white, $alpha: .2);
                    // background-color: $btn_blue_color2;
                    // border-color: $btn_blue_color2;
                }

                & input[type=text] {
                    min-width: 250px;
                    font-size: .9rem;
                    font-weight: 100;
                    padding-left: 10px;

                    // color: #636262;
                    border-radius: 5px;
                    border: 1px solid rgba(0, 0, 0, 0.4);

                    -webkit-transition: 0.2s;
                    transition: 0.2s;
                    outline: none;
                    box-sizing: border-box;
                    box-shadow: 1px 1px 10px rgba($color: black, $alpha: .2);

                    &:focus {
                        transition: .2s;
                        // border: 2px solid rgba(16, 172, 50, 0.598);
                        border: 2px solid white;
                        // border:2px solid rgba($color: $amanin_logo_color2, $alpha: .8);
                        background-color: rgba($color: rgb(231, 231, 231), $alpha: 1);
                        // color: white;
                        box-shadow: 1px 1px 10px rgba($color: white, $alpha: .5);
                    }

                }

                &>span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 20px;
                    color: #bbbaba;
                    font-weight: 400;
                    transition: .3s;

                    &.enable {
                        color: rgb(255, 255, 255);
                        transition: 0.2s;
                        font-weight: bold;
                        transition: .3s;
                        
                        &:hover {
                            cursor: pointer;
                            color: #242d30;
                            transition: .3s;
                        }
                    }
                }
            }

            & .help {
                display: flex;
                border-top: 1px solid rgba(0, 0, 0, 0.175);
                margin-top: 50px;
                padding-top: 10px;

                &_button {
                    border: 1px solid rgb(193, 193, 193);
                    display: flex;
                    align-items: center;
                    padding: 10px;
                    border-radius: 5px;
                    font-size: .8rem;
                    // color: rgb(70, 70, 70);
                    margin: 5px;
                    box-shadow: 1px 1px 10px rgba($color: black, $alpha: .2);
                    background-color: rgba($color: white, $alpha: .1);
                    transition: .3s;
                    color: #ffffff;

                    &:hover {
                        transition: .3s;
                        background-color: rgba(33, 33, 46, 0.2);
                        cursor: pointer;
                        // transform: .2s;
                        color: white;
                        box-shadow: 1px 1px 10px rgba($color: white, $alpha: .2);
                    }

                    &>img {
                        margin-right: 5px;
                    }
                }
            }

            & .addibutton {
                margin-top: 10px;
                display: flex;
                justify-content: flex-start;
                padding-bottom: 50px;
                border-bottom:1px solid rgba($color: white, $alpha: .5);
                // border:2px solid red;
                padding-left: 5px;

                &>div {
                    font-weight: 400;
                    padding: 10px;
                    box-shadow: 1px 1px 10px rgba($color: black, $alpha: .2);
                }
            }

            & .learnmore {
                margin-top: 10px;
                font-size: .75rem;
                color: white;
                &>a {
                    text-decoration: none;
                    color: white;
                    transition: .3s;
                    white-space: nowrap;
                    &:hover {
                        transition: .3s;
                        color: rgba($color: rgb(228, 238, 30), $alpha: .8);
                    }
                }
                &>span {
                    margin-left: 5px;
                    margin-right: 5px;
                    font-weight: bold;
                    font-size: .75rem;
                }
            }
        }

        &_r {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // border: 2px solid blue;
            width: 600px;

            &_i {
                display: flex;
                align-items: center;

                & .dir {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: rgba(255, 255, 255, 0.356);
                    color: rgb(64, 87, 164);
                    // border: 1px solid #ababab;
                    // color: #ababab;
                    margin: 20px;
                    width: 40px;
                    height: 40px;
                    border-radius: 40px;
                    transition: .5s;

                    &:hover {
                        background-color: #ababab;
                        cursor: pointer;
                        color: white;
                    }
                }

                & .img {
                    width: 350px;
                    height: 350px;
                    overflow: hidden;
                    // border: 1px solid #ababab;
                    // border:2px solid red;
                    border-radius: 250px;
                    position: relative;
                    transition: .5s;

                    &>img {
                        position: absolute;
                        z-index: 100;
                    }
                }
            }

            &_t {
                // border: 1px solid red;
                display: flex;
                justify-content: center;
                margin-top: 20px;
                flex-direction: column;
                align-items: center;

                & .title {
                    font-size: 1.2rem;
                    font-weight: 330;
                    margin-bottom: 10px;
                }

                & .title2 {
                    // border:1px solid blue;
                    font-size: .95rem;
                    font-weight: 350;
                    max-width: 450px;
                    min-height: 35px;
                    text-align: center;
                    // color: #575656;

                }
            }

            &_d {
                // border:1px solid red;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 20px;

                &>div {
                    width: 6px;
                    height: 6px;
                    // border:1px solid red;
                    border-radius: 10px;
                    background-color: #dcdcdc;
                    margin-left: 2px;
                    margin-right: 2px;
                    transition: .5s;

                    &.chosen {
                        background-color: rgb(23, 106, 232);
                    }
                }
            }
        }
    }

    &_bottom2 {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        border: 2px solid green;
        height: 80px;
        padding: 10px;

        &_help {
            border: 1px solid rgb(193, 193, 193);
            display: flex;
            align-items: center;
            padding: 10px;
            border-radius: 5px;
            transition: .2s;
            font-size: .8rem;
            color: rgb(70, 70, 70);
            margin: 5px;

            &:hover {
                background-color: rgba(33, 33, 46, 0.2);
                cursor: pointer;
                transform: .2s;
            }

            &>img {
                margin-right: 5px;
            }
        }
    }
}

.profile {
    display: flex;
    align-items: center;
    z-index: 10;
    &>img {
        height: 70px;
        margin: 10px;
    }
}

.amanin {
    display: flex;
    position: absolute;
    bottom: 10px;
    right: 10px;
}


.cemodal {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: white;
    color: black;
    box-shadow: 1px -1px 5px #000;
    border-radius: 5px;
    min-width: 200px;
    min-height: 50px;
    transition: transform .5s;

    // &_show {
    //     border:2px solid red !important;
    // }

    // &_close {
    //     width: 0px !important;
    //     height: 0px !important;
    //     border:2px solid blue;
    // }

    &>img {
        position: absolute;
        height: 20px;
        padding: 3px;
        margin: 5px;
        right: 0;
        top: 0;
        border-radius: 3px;

        &:hover {
            background-color: #b4aeae;
            cursor: pointer;
        }
    }

    &>div {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5px;

        & .cl {
            font-size: .65rem;
            padding: 5px;
        }
    }

    &>span {
        // margin: 10px;
        padding: 10px;
        font-size: .95rem;
        margin-top: 5px;
        margin-bottom: 5px;
        transition: .2s;

        &:hover {
            cursor: pointer;
            background-color: rgb(208, 231, 243);
            transition: .2s;
        }
    }

}