@import "../../../assets/scss/common.scss";

$separator: rgba(199, 226, 158, 0.5);

.participant {
    display: flex;
    flex-direction: column;
    width: 240px;

    &_c {
        display: flex;
        padding: 10px;
        justify-content: center;
        align-items: flex-start;
        margin: 1px;
        transition: .3s ease;

        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
            cursor: pointer;
        }

        &>img {
            background-color: white;
            max-height: 70px;
            border-radius: 5px;
        }

        &>div {
            width: 100%;
            margin-left: 10px;
            display: flex;
            flex-direction: column;

            &>span {
                color: rgb(167, 167, 167);
                font-size: .65rem;

                &.id {
                    font-weight: bold;
                    word-break: break-all;
                    color: rgb(255, 255, 255);
                }

                &.chat {
                    border: 1px solid rgb(66, 89, 61);
                    background-color: rgba(66, 89, 61, 0.57);
                    margin-top: 5px;
                    border-radius: 3px;
                    font-size: .7rem;
                    padding: 2px;
                }
            }
        }
    }
}

.separator {
    // border-radius: 1px !important;
    margin-left: 10px;
    margin-right: 10px;
    border-top: 1px solid $separator;
    height: 0px !important;
    margin-top: 2px;
    margin-bottom: 2px;
}

.chosen {
    background-color: rgb(64, 76, 83);
}

.info {
    font-size: .55rem;
    display: flex;
    // border:1px solid red;
    align-items: flex-start;
    justify-content: flex-start;

    &>div {
        padding: 2px;
        border-radius: 3px;
        color: white;
        margin: 1px;

        &.blocked {
            color: red;
            font-weight: bold;
            background-color: white;
            border: 1px solid whitesmoke;
        }

        &.online {
            background-color: rgb(0, 84, 0);
            border: 1px solid green;
        }

        &.offline {
            background-color: rgb(84, 0, 15);
            border: 1px solid rgb(128, 0, 34);
        }

        &.star {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgb(58, 43, 70);
            border: 1px solid rgb(102, 0, 139);

            &>img {
                width: 10px;
            }
        }

        &.flag {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgb(255, 255, 255);
            // border: 1px solid rgb(102, 0, 139);
            border: 1px solid white;

            &>img {
                width: 10px;
            }
        }

    }
}