@import "../../assets/scss/common.scss";

.fullscreen_container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    // width: 100%;
    // height: 100vh;
    display: flex;
    flex-direction: column;
    // justify-content: flex-end;
    justify-content: flex-start;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
    border: 0px solid red;

    &_v {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &_video {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    // Overlay untuk mode portrait
    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // background-color: rgba(0, 0, 0, 0.8);
        background-color: rgba(0, 0, 0, 0.9);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;

        &_message {
            color: #fff;
            font-size: 18px;
            text-align: center;
            padding: 20px;
            border: 2px dashed #82c9ec;
            border-radius: 10px;
            background-color: rgba(0, 0, 0, 0.9);
        }
    }

    .logout_button {
        position: fixed;
        bottom: 20px;
        right: 20px;
        padding: 10px 18px;
        background-color: #82c9ec;
        /* Warna utama sesuai preferensi */
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        border: none;
        border-radius: 50px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        cursor: pointer;
        transition: transform 0.2s ease, background-color 0.3s ease;

        &:hover {
            background-color: darken(#82c9ec, 10%);
            transform: scale(1.1);
        }

        &:active {
            transform: scale(1);
            background-color: darken(#82c9ec, 20%);
        }

        &:focus {
            outline: none;
            box-shadow: 0 0 0 3px rgba(130, 201, 236, 0.5);
        }

        @include responsiveMobileSmall() {
            padding: 6px 14px;
            font-size: 12px;
            bottom: 10px;
            right: 10px;
            // border: 5px solid red;
        }

        @include responsiveMobileMedium() {
            padding: 8px 16px;
            font-size: 14px;
            bottom: 15px;
            right: 15px;
            // border: 5px solid green;
        }

        @include responsiveMobileStandard() {
            padding: 10px 18px;
            font-size: 16px;
            bottom: 20px;
            right: 20px;
            // border: 5px solid yellow;

        }



    }

}

.navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #000;
    border-top: 3px solid white;
    padding: 10px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.navItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #fff;
    font-size: 14px;

    img {
        width: 24px;
        height: 24px;
        margin-bottom: 5px;
    }

    &.centerItem {
        position: relative;
        top: -30px;

        .circle {
            // background-color: #6c63ff;
            background-color: $amanin_logo_color2;
            border: 4px solid #fff;
            border-radius: 50%;
            padding: 10px;
            display: flex;
            flex-direction: column;
            /* Ensure items are aligned in a column */
            justify-content: center;
            align-items: center;

            img {
                color: #fff;
                width: 30px;
                height: 30px;
                margin-bottom: 5px;
                /* Add margin to space out the image and text */
            }

            span {
                color: #fff;
                font-size: 12px;
                margin-top: 5px;
                /* Add margin to space out the image and text */
            }
        }
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(180deg);
    }
}

@keyframes spinReverse {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-180deg);
    }
}

.spin {
    animation: spin 0.5s linear infinite;
}

.spinReverse {
    animation: spinReverse 0.5s linear infinite;
}

@media (max-width: 768px) {
    .navbar {
        padding: 5px 0;
    }

    .navItem {
        font-size: 12px;

        img {
            width: 20px;
            height: 20px;
        }

        &.centerItem {
            top: -20px;

            .circle {
                padding: 8px;

                img {
                    width: 24px;
                    height: 24px;
                }

                span {
                    font-size: 10px;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .navbar {
        padding: 3px 0;
    }

    .navItem {
        font-size: 10px;

        img {
            width: 18px;
            height: 18px;
        }

        &.centerItem {
            top: -15px;

            .circle {
                padding: 6px;

                img {
                    width: 20px;
                    height: 20px;
                }

                span {
                    font-size: 8px;
                }
            }
        }
    }
}