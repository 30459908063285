@import "../assets/scss/common.scss";

.container {
    display: flex;
    align-items: flex-end;

    &_small {
        // border:1px solid red;
        height: 5px !important;
    }

    &_bar {
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: space-evenly;

        &.small {
            height: 5px !important;
        }

        &>div {
            height: 100%;
            width: 5px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            &>div {
                background-color: rgb(195, 74, 202);
                width: 2px;
                border-radius: 2px;
                border: 1px solid rgb(195, 74, 202);
            }
        }
    }

    &>div.textsmall {
        // border: 1px solid green;
        height: 12px;
        width: 15px;
        position: relative;
        &>span {
            font-size: .5rem;
        }
    }

    &>span {
        display: flex;
        text-align: end;
        font-size: .55rem;
        color: rgb(66, 42, 67);
    }
}