@import "../assets/scss/common.scss";

.container {
    font-size: .75rem;
    position: relative;
    // border:2px solid red;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    &>img {
        transition: .3s;

        &:hover {
            cursor: pointer;
            // border-color: $amanin_logo_color2;
            border-color: $amanin_logo_color2;
            transition: .3s;
        }
    }

    &_modal {
        position: absolute;
        z-index: 202;

        // min-height: 500px;
        min-width: 300px;
        background-color: $bg_color_dark;
        right: 0;
        top: 0px;
        border-radius: 10px;
        padding: 10px 10px 0px 10px;
        box-shadow: 1px 1px 10px rgba($color: white, $alpha: .5);

        & .top {
            // border:2px solid white;
            display: flex;
            justify-content: flex-end;

            & .hi {
                font-size: 2.0rem;
                font-weight: 0;
                flex-grow: 1;
                display: flex;
                text-align: center;
                align-items: center;
                justify-content: flex-start;
                white-space: nowrap;
                margin-right: 20px;
                margin-left: 0px;
                // border:1px solid red;
            }

            &>span {
                font-size: 1.3rem;
                width: 20px;
                border: 2px solid red;
                text-align: center;
                margin-left: 5px;

                // margin-right: 5px;
                &:hover {
                    cursor: pointer;
                    color: #ababab;
                }
            }
        }

        & .bottom {
            margin-top: 5px;

            &>div {
                // min-height: 50px;
                padding: 5px;
                background-color: rgba(255, 255, 255, 0.1);
                border-radius: 5px;
                margin: 2px;
                display: flex;
                flex-direction: row;
                align-items: center;
                

                &.exam {
                    flex-direction: column;
                    align-items: flex-start;
                    & .exam_title {
                        margin-right: 5px;
                        white-space: nowrap;
                    }

                    & .exam_value {
                        display: flex;
                        flex-direction: column;

                        &>span {
                            white-space: nowrap;
                        }

                        &>ol {
                            &>li {
                                &>div {
                                    // border: 1px solid red;
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    &>img {
                                        margin-left: 5px;

                                        &:hover {
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &.logout {
                    background-color: transparent;
                    justify-content: flex-end;
                    padding-right: 0px;
                }

                &.legal {
                    background-color: transparent;
                    justify-content: center;
                    font-size: .6rem;

                    &>a {
                        margin-left: 5px;
                        margin-right: 5px;
                        color: white;
                        text-decoration: none;

                        &:hover {
                            color: yellow;
                        }
                    }
                }
            }
        }
    }
}

.img {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    border: 3px solid white;
}

.link {
    text-decoration: none;
    color: rgb(142, 223, 250);
    white-space: nowrap;
    &:hover {
        color: yellow;
    }
}