@import "../../../assets/scss/common.scss";

.container {
    // border:2px solid blue;
    height: 100%;
    width: calc(100% - 00px);
    background-color: rgba(221, 195, 139, 0.1);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    color: white;
    font-size: .75rem;
    transition: all ease 1s;
    column-gap: 10px;
    overflow-x: auto;

    span {
        margin: 5px;
    }

    &_navigation {
        // border: 2px solid yellow;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        column-gap: 20px;
    }

    &_first {
        // border: 2px solid red;
        display: flex;

        flex-direction: row;
        justify-content: space-around;

        // overflow-y: auto;
        .modal {
            display: flex;
            flex-direction: column;
            width: 100%;
            transition: opacity 0.3s ease-in-out, visibility 0s linear 0.3s;
            align-items: center;
            justify-content: center;
            padding-bottom: 5px;
            padding-top: 5px;

            &_w {
                display: flex;
                justify-content: center;
                align-items: center;
                max-width: 600px;
                font-size: .7rem;
                background-color: rgb(39, 104, 119);
                border: 1px solid rgb(119, 102, 21);
                padding: 5px;
                border-radius: 5px;
                flex-direction: column;

                color: white;

                &>div {
                    display: flex;
                    align-items: center;

                    &.i {
                        font-size: .65rem;
                        font-style: italic;
                        text-align: center;
                    }
                }
            }

            &_c {
                display: flex;

                &_profile {
                    display: flex;
                    flex-direction: row;

                    &_left {
                        display: flex;
                        flex-direction: column;
                        flex-wrap: wrap;

                        &_show {
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            & .profile {
                                background-color: rgb(200, 227, 228);
                                border: 1px solid rgb(112, 148, 183);
                                text-align: left;
                                font-size: .75rem;
                                min-width: 290px;
                                margin-left: 5px;
                                margin-right: 5px;
                                margin-top: 5px;
                                margin-bottom: 5px;
                                border-radius: 5px;
                            }
                        }
                    }

                    &_right {
                        display: flex;
                        flex-direction: column;
                        text-align: center;
                        margin: 5px;
                        border-radius: 5px;
                        color: white;
                        align-items: center;
                        justify-content: flex-start;

                        &>span {
                            padding-bottom: 3px;
                            color: black;
                        }

                        .direction {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border: 2px solid rgb(110, 145, 227);
                            margin: 2px;
                            color: black;
                            padding: 2px;
                            white-space: nowrap;

                            &:hover {
                                background-color: rgba(110, 145, 227, 0.54);
                                cursor: pointer;
                            }
                        }

                        .active {
                            background-color: rgba(110, 145, 227, 0.54);
                        }

                        .chat_container {
                            background-color: $amanin_logo_color;
                        }

                        &_photos {
                            display: flex;
                            align-items: flex-start;
                            font-size: .75rem;
                            animation: fadeIn 2.5s;

                            &>div {
                                margin: 3px;
                                padding: 5px;
                                border-radius: 5px 5px 0px 0px;
                                background-color: rgb(82, 80, 80);
                                color: white;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                font-weight: bold;

                                &>span {
                                    margin-bottom: 3px;
                                }

                                &.facevalidated {
                                    & .sim {
                                        margin: 3px 3px 3px 3px !important;
                                        padding: 3px 5px 3px 5px;
                                        border-radius: 5px;
                                        background-color: rgb(28, 28, 28);
                                        font-weight: bold;
                                    }
                                }
                            }
                        }
                    }
                }


                &_button {
                    margin: 5px;
                    display: flex;

                    &>button {
                        margin: 10px;
                    }
                }
            }

        }
    }

    &_second {
        // border: 2px solid green;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        textarea {
            resize: none;
            padding: 10px;
        }
    }
}