@import "../assets/scss/common.scss";

.container {
    display: inline-flex;
    align-items: center;
    position: relative;
    width: 100px;
    height: 100px;

    &>div {
        position: absolute;
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    @media only screen and (max-width: 421px) {
        width: 80px;
        height: 80px;
        align-items: center;
        justify-content: center;

        &>div {
            &>img {
                width: 80%;
            }
        }
    }


}

@keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-o-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-moz-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@-webkit-keyframes flickerAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.animateflicker {
    -webkit-animation: flickerAnimation 1s infinite;
    -moz-animation: flickerAnimation 1s infinite;
    -o-animation: flickerAnimation 1s infinite;
    animation: flickerAnimation 1s infinite;
}