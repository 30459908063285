@import "../assets/scss/common.scss";

.container {
    display: flex;
    width: 98%;
    height: 98vh;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    background-color: rgb(184, 184, 184);

    &>div {
        margin: 10px;
    }
}