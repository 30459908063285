@import "../assets/scss/common.scss";

.container {
    display: flex;
    align-items: center;
    justify-content: center;

    &_btn {
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        padding: 5px;
        border-radius: 5px;
        background: linear-gradient(135deg, #4BC0C8, #7c97b3);
        // color: rgb(26, 73, 82);
        font-weight: bold;

        &:hover {
            cursor: pointer;
            transform: scale(1.05);
            box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.3);
        }

        &:active {
            transform: scale(0.8);
        }
    }
}