@import "../assets/scss/common.scss";


.overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    font-family: $amanin_font_family;
    font-size: .75rem;
    z-index: 1100;
    transition: 2s ease-in-out;
    background-color: rgb(0, 0, 0, 0.5);

    .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        // border:1px solid red;
        width: 100%;
        padding: 10px;

        .button2Help {
            margin: 3px;
        }
    }

    .image_content {
        margin: 10px auto;
        display: flex;
        flex-direction: column;
        background-color: white;
        width: 95%;
        height: 95%;
        border-radius: 5px;
        box-shadow: 1px 1px 10px rgba(255, 255, 255, 0.438);
        overflow-x: auto;
        align-items: center;
        justify-content: center;

        &_container {
            display: flex;
            justify-content: center;
            // box-shadow: 0px 0px 0px 99999px rgb(73, 73, 73);
            // transition: top .2s, left .2s;

            img {
                transition: .2s;

                &.zoomIn {
                    &:hover {
                        cursor: zoom-in;
                    }
                }

                &.zoomOut {
                    &:hover {
                        cursor: zoom-out;
                    }
                }
            }
        }
    }

    .notes {
        background-color: beige;
        // color: red;
        font-size: 18px;
        font-weight: 600;
        padding: 10px 20px;
        text-align: center;
    }
}

.transitionactive {
    transition: top .2s, left .2s !important;
}

.transitioninactive {
    transition: all 0s ease 0s !important;
}