@import "../assets/scss/common.scss";

.container {
    display: inline-flex;
    position: relative;

    &>img {
        width: 10px;
    }
    
    &_img2 {
        position: absolute;
        left: 5px;
    }
}