@import "../assets/scss/common.scss";

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.screenshot_wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.442);
    border-radius: 3px;
    height: max-content;
    width: max-content;
    margin: 3px;
    background-color: rgba(0, 0, 0, 0.6);
    animation: fadeIn 3s;

    span {
        font-size: 0.70rem;
        font-weight: bold;
        margin-top: 2px;
        margin-bottom: 2px;
    }

    .screenshot_image {
        display: flex;
    }

    img {
        height: 90px;
    }

    pre {
        height: max-content;
        text-align: center;
        padding: 2px;
        font-family: $amanin_font_family;
        margin: 0;
    }

    textarea {
        height: max-content;
        text-align: start;
        padding: 2px;
        font-family: $amanin_font_family;
        margin: 0;
    }

    .btn_wrapper {
        display: flex;
        justify-content: space-between;
        padding: 2px;
        gap: 5px;



        button {
            width: 100%;
            cursor: pointer;
        }
    }
}