.device_warning {
    margin: 5px;
    background-color: rgba(92, 14, 14, 0.8);
    border-radius: 5px;
    font-size: .85rem;
    color: white;
    display: flex;
    flex-direction: column;

    &>div {
        margin: 2px;
        border: 1px solid white !important;
        display: flex;
        flex-direction: row !important;
        &>span {
            // background-color: white;
            border-radius: 5px;
            margin: 2px;

            &.kind {
                display: inline-block;
                min-width: 70px;
            }
        }

        &.info {
            margin: 5px;
            border-width: 0px !important;
            text-align: center;
            font-weight: bold;
            max-width: 400px;
            display: flex;
            flex-direction: column !important;
        }
    }
}