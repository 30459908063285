@import "../assets/scss/common.scss";

@mixin rotate-infinitely($duration: 2s) {
    animation: rotate $duration ease-in-out infinite;
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.container {
    @include rotate-infinitely(1s); // Adjust the duration as needed
    width: 100px;
    height: 100px;
    border-radius: 50%;
}