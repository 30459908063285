@import "../assets/scss/common.scss";

.container {
    position: relative;

    .audioPlayer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 400px;
        width: 100%;
        background-color: #343434;
        padding: 20px;
        border-radius: 12px;
        // color: $amanin_logo_color2;
        color: white;
        font-family: Arial, sans-serif;
        position: relative;

        &_title {
            font-size: 16px;
            font-weight: bold;
            color: #ffd700;
            // color: $amanin_logo_color;
            margin-bottom: 15px;
            text-align: center;
        }

        .audioSelect {
            background-color: #444;
            color: white;
            border: 1px solid #ffd700;
            border-radius: 5px;
            padding: 4px;
            width: 100%;
            margin-bottom: 15px;
            margin-top: 15px;
            font-size: 16px;
            cursor: pointer;
            text-align: center;

            &:focus {
                outline: none;
                border-color: #ffd700;
            }
        }

        .buttonWrapper {
            display: flex;
            justify-content: center; // Center all items horizontally in the container
            align-items: center; // Vertically center items within the container
            max-width: 100%;
            width: 100%;
            padding: 10px;
            // border: 3px solid red;


            .playButton {
                background: linear-gradient(135deg, $amanin_logo_color, #5aaed6);
                border: none;
                padding: 5px 25px;
                color: white;
                font-size: 14px;
                font-weight: bold;
                border-radius: 50px;
                cursor: pointer;
                transition: transform 0.3s ease, box-shadow 0.3s ease;
                margin-bottom: 10px;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);

                &:hover:not(:disabled) {
                    transform: scale(1.05);
                    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
                }

                &:disabled {
                    background: #969393;
                    cursor: not-allowed;
                    opacity: 0.6;
                }
            }

            .infoIcon {
                position: absolute;
                cursor: pointer;
                right: 0;
                margin-right: 25px;
                transition: transform 0.3s ease, opacity 0.3s ease;


                & img {
                    width: 25px;
                    height: auto;
                    transition: transform 0.3s ease;
                }

                &:hover {
                    img {
                        transform: scale(1.1);
                    }
                }

                .tooltip {
                    position: absolute;
                    left: 150%;
                    top: -150%;
                    width: 200px;
                    height: 100px;
                    display: none;
                    // display: block;
                    background-color: #aaa;
                    color: #181010;
                    text-align: left;
                    font-weight: 600;
                    border-radius: 5px;
                    padding: 10px;
                    font-size: 14px;
                    transition: opacity 0.3s ease;


                    &:before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        right: 100%;
                        transform: translateY(-50%);
                        border-width: 5px;
                        border-style: solid;
                        border-color: transparent #aaa transparent transparent;
                    }
                }

                &:hover .tooltip {
                    display: block; // Show the tooltip when hovering over the icon
                    opacity: 1; // Fade-in effect
                }

            }

        }

        .closeButton {
            position: absolute;
            top: -10px;
            right: -10px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #343434;
            border: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

            transition: transform 0.3s ease, box-shadow 0.3s ease;

            &:hover:not(:disabled) {
                transform: scale(1.1);
            }

            &:disabled {
                cursor: not-allowed;
                opacity: 0.5;
            }
        }

        .closeImage {
            width: 60%;
            height: 60%;
            object-fit: contain;
        }

        .timeBarContainer {
            width: 100%;
            height: 8px;
            background-color: #555;
            border-radius: 4px;
            margin: 15px 0;
            overflow: hidden;
        }

        .timeBar {
            height: 100%;
            background-color: $amanin_logo_color;
            transition: width 0.2s;
        }

        .timeInfo {
            display: flex;
            justify-content: space-between;
            width: 100%;
            font-size: 14px;
            margin-top: 10px;
        }

    }
}

.customButton {
    position: relative;
    z-index: 5000;
    font-size: .8rem;
    font-weight: bold;
    margin-left: 10px;
    height: 30px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &.audioPlayer {
        background: linear-gradient(135deg, #FF7E5F, #FD3A69);
        box-shadow: 0px 4px 10px rgba(255, 85, 85, 0.4);
    }

    &:hover {
        transform: scale(1.05);
        box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.3);
    }

    &:active {
        transform: scale(0.8);
    }

    &:disabled {
        background: #969393;
        color: white;
        cursor: auto;
        box-shadow: none;
        transform: scale(1);
    }
}