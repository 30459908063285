@import "../assets/scss/common.scss";

.container {
    font-family: $amanin_font_family;

    &>table>tr>td {
        padding: 0px 8px 0px 5px;
    }
}


.audioToggle {
    display: inline-block;
    position: relative;
    width: 54px;
    height: 28px;
    margin-right: 5px;
}

/* Hide default checkbox */
.audioToggle input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* Create custom checkbox */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}

/* When switch is toggled on */
input:checked+.slider {
    background-color: #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

/* Round slider */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.valid {
    color: green;
    font-weight: bold;
    margin-right: 5px;
}

.invalid {
    color: red;
    font-weight: bold;
    margin-right: 5px;
}