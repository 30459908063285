@import "../assets/scss/common.scss";

.container {
    &_logout {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .75rem;
        font-family: $amanin_font_family;
        font-weight: normal;
        background-color: white;
        padding-right: 5px;
        border-radius: 5px;
        height: 30px;

        &>img {
            margin-right: 3px;
        }

        &:hover {
            background-color: rgba(255, 255, 255, 0.83);
            cursor: pointer;
        }
    }

}