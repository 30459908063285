@import "../assets/scss/common.scss";

@keyframes scaleTransition {
    from {
        transform: scale(0, 0);
    }

    to {
        transform: scale(1, 1);
    }
}

.container {
    position: fixed;
    background-color: rgb(156, 231, 19);
    padding: 3px;
    right: 0px;
    top: 0px;
    z-index: 303;
    transform-origin: top right;
    animation: scaleTransition 1s ease forwards;
    box-shadow: 1px 1px 10px rgba(58, 126, 8, 0.5);
    border-bottom: 1px solid rgba(57, 126, 8, 0.5);
    border-left: 1px solid rgb(58, 126, 8, 0.5);
    border-bottom-left-radius: 5px;
    display: flex;
    font-family: $amanin_font_family;
    font-size: .65rem;
    align-items: center;

    &>img {
        width: 30px;
        margin: 3px;

        &:hover {
            cursor: pointer;
        }

        &.hide {
            width: 15px;
            margin: 0px;
        }
    }

    &>div {
        max-width: 250px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        transition: width 1s ease, height 1s ease;

        &>span {
            &.keep {
                white-space: nowrap;
            }
        }

        &>button {
            margin: 0px;
            padding: 0px;
            font-weight: normal;
            margin-top: 4px;
            font-size: .65rem;
        }
    }
}