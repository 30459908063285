@import "../assets/scss/common.scss";

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.container {
    position: fixed;
    background: linear-gradient(45deg,
            rgb(15, 39, 79),
            #33454e,
            #0e2e37,
            #363e53);

    height: 100vh;
    z-index: 1001;
    width: 100%;
    font-family: $amanin_font_family;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &_mt {
        font-size: 1.7rem;
        color: white;
    }

    &_st {
        font-size: 1.25rem;
        color: white;
        margin: 10px;
    }
}

.open_fadein {
    animation: fadeIn 1s;
}

.close_fadeout {
    animation: fadeOut 1s;
}