@import "../../../assets/scss/common.scss";

$separator: rgba(199, 226, 158, 0.5);

.container {
    border: 2px solid transparent;
    height: calc(100% - 4px);
    display: flex;
    align-items: center;
    justify-content: center;

    &>img {
        width: 100px;
    }
}

.search {
    border: 1px solid $separator;
    flex-direction: column;
    border-radius: 4px;
    margin: 5px;
    padding: 5px;
    display: flex;

    &_c {
        flex-grow: 1;
        display: flex;

        &>input[type="text"] {
            color: rgb(202, 202, 202);
            background-color: rgb(55, 55, 55);
            width: 100%;
            outline: none;
            border: none;
            padding: 5px;
            font-size: .75rem;
        }

        &_filter {
            // background-color: white;
            display: flex;
            align-items: center;
            margin-left: 1px;
            padding: 3px;
            border-radius: 0 3px 3px 0;

            &>img {
                height: 18px;
            }

            &>span {
                font-size: .6rem;
                white-space: nowrap;
                color: white;
                padding-left: 3px;
            }

            &:hover {
                cursor: pointer;
                background-color: rgba(255, 255, 255, 0.194);
            }
        }

        &_check {
            font-size: .75rem;
            display: flex;
            flex-grow: 1;
            margin-top: 5px;
            // border:1px solid green;

            &_c {
                // border: 1px solid red;
                display: flex;
                align-items: center;
                color: white;

                &>span {
                    margin-left: 3px;
                }
            }
        }
    }
}

.screenshot_card_wrapper {
    border: 4px solid rgb(160, 170, 185);
    padding: 10px;
    margin-inline: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: .3rem;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 3px;
        background: #777fae;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }

    .screenshot_card {
        // border: 3px solid rgb(147, 146, 146);
        flex: 1 1 20%;
        max-width: 20%;
        box-sizing: border-box;
        margin: 3px;

    }

}

.navigation {
    display: flex;
    gap: 3px;

    .direction {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid rgb(110, 145, 227);
        margin: 2px;
        color: black;
        padding: 2px;
        white-space: nowrap;

        &:hover {
            background-color: rgba(110, 145, 227, 0.54);
            cursor: pointer;
        }
    }

    .active {
        background-color: rgba(110, 145, 227, 0.54);
    }
}

.flagAttention {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 600px;
    background-color: rgb(39, 104, 119);
    border: 1px solid rgb(119, 102, 21);
    padding: 5px;
    border-radius: 5px;
    flex-direction: column;

    &_text {
        font-size: .7rem;
        font-weight: bold;
        margin-left: 5px;
    }

    color: white;

    &>div {
        display: flex;
        align-items: center;

        &.i {
            font-size: .65rem;
            font-style: italic;
            text-align: center;
        }
    }

}