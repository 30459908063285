@import "../../assets/scss/common.scss";

.container {
    border: 1px solid rgba(255, 255, 255, 0.5);
    height: 100%;
    width: 100%;
    background-color: #343434;
    color: white;
    border-radius: 5px;
    font-family: $amanin_font_family;
    font-size: .75rem;
    box-shadow: 0px 4px 6px rgba(255, 255, 255, 0.2);

    &_header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 3px;

        &>span {
            text-align: center;

            &.id {
                margin-bottom: 5px;
                font-weight: bold;
            }
        }
    }

    &_video {
        display: flex;
        // border:1px solid blue;

        &>div {
            flex-grow: 1;
            // border:1px solid red;
            display: flex;
            align-items: center;
            height: 120px;
            align-items: center;
            justify-content: center;

            &>video {
                width: 100%;
                height: 100%;
                background-color: rgba(255, 255, 255, 0.2);
            }
        }
    }

    &_bottom {
        display: flex;
        justify-content: center;
        margin: 10px;
    }
}

.duration {
    font-size: 1.5rem;
    font-weight: bold;
}

.info {
    padding: 5px;
}

.endcall {
    padding: 2px;
    background-color: red;
    border-radius: 50px;
    width: 30px;

    &:hover {
        cursor: pointer;
        background-color: rgba(255, 0, 0, 0.8);
    }
}