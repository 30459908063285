.container {
    display: flex;
    border: 1px solid rgb(71, 146, 117);
    border-radius: 4px;
    height: 20px;
    width: 20px;
    justify-content: center;
}

.checkbox {
    display: block;
    position: relative;
    padding-left: 28px;
    top: 3px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &>input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    & .checkmark {
        position: absolute;
        top: -7px;
        left: 0;
        height: 28px;
        width: 28px;
        background: url("../assets/imgs/unchecked.png");
        background-repeat: no-repeat;
        background-size: 28px 28px;
    }
    &>input:checked~.checkmark {
        background: url("../assets/imgs/checked.png");
        background-repeat: no-repeat;
        background-size: 28px 28px;
    }
}