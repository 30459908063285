@import "../assets/scss/common.scss";

.container {
    background-color: #1d1b1b;
    display: flex;
    margin: 1px;
    padding: none;

    &_v {
        position: relative;
        width: 100%;
        height: 100%;

        &_video {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            // @media only screen and (max-width: 768) {
            //     & {
            //         video {
            //             width: 50px !important;
            //             height: 50px;
            //         }
            //     }
            // }
        }

        &_r {
            position: absolute;
            bottom: 3px;
            left: 3px;
            width: 100%;
            display: flex;
            justify-content: left;
            align-items: center;
            text-align: center;

            &>span {
                position: absolute;
                bottom: 2px;
                left: 20px;
                font-size: 0.8rem;
                font-weight: bold;
            }

            &_r {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 12px;
                height: 12px;
                border: 2px solid rgb(58, 56, 56);
                border-radius: 10px;
                margin-right: 2px;

                &_d {
                    width: 8px;
                    height: 8px;
                    background-color: rgb(173, 76, 76);
                    border-radius: 8px;
                    border: 2px solid red;

                }
            }
        }

        &_ai {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            padding: 3px;
            font-weight: bold;
            text-align: center;
            color: $amanin_logo_color3;
        }
    }

    // @media only screen and (max-width: 768px) {
    //     & {
    //         width: 80px !important;
    //         height: 55px !important;
    //     }
    // }

}