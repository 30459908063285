@import "../assets/scss/common.scss";

.container {
    width: 100px;
    height: 100px;
    position: relative;
    border:1px solid red;
    border:1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s;

    &:hover {
        border: 1px;
        cursor: pointer;
        border:1px solid white;
        transition: .3s;
    }

    &_1 {
        position: absolute;
        border-right: 1px solid white;
        height: 100%;
        transform: rotate(45deg)
    }
    &_2 {
        position: absolute;
        border-right: 1px solid white;
        height: 100%;
        transform: rotate(-45deg)   
    }
}