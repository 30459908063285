@import "../../assets/scss/common.scss";

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.container_pseudo {
    z-index: 0;
}

.container {
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    margin: 2px;
    border-radius: 10px;
    animation: fadeIn 2s;
    // border:2px solid red;

    &_v {
        position: relative;
        display: flex;
        background-color: black;
        align-items: flex-start;
        // justify-content: center;
        flex-direction: column;

        // border:1px solid red;
        &:hover {
            cursor: pointer;
        }

        &_hear {
            bottom: -0px;
            left: 0px;
            position: absolute;
            z-index: 2;
            background-color: white;
            border: 1px solid red;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                cursor: pointer;
                background-color: rgba(145, 118, 118, 0.383);
            }
        }

        &_row {
            // border:2px solid red;
            display: flex;
        }

        &_msg {
            position: absolute;
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: white;
            border-radius: 5px;
            border: 4px solid rgba(255, 0, 0, 0.8);
            margin: auto;

            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            &>img {
                width: 35px;
                border-radius: 5px;
            }

        }

        &_validation {
            position: absolute;
            z-index: 2;
            top: -3px;
            left: -3px;
            border: 1px solid rgb(135, 135, 135);
            width: 17px;
            height: 17px;
            border-radius: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &_abs {
            position: absolute;
            z-index: 2;
            top: -12px;
            width: 12px;
            background-color: white;
            border-left: 0px;
            border-bottom: 0px;
            object-fit: cover;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            transition: transform 0.3s ease;

            &_livevideo {
                right: 0px;
            }

            &_webcam {
                right: 12px;
            }

            &_screencap {
                right: 24px;
            }

            &_audio {
                right: 84px;

                &:hover {
                    transform: scale(2);
                    z-index: 3;
                }
            }

            &_avg {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 12px;
                width: 48px;
                right: 36px;
            }

            &_info {
                left: 0px;
                width: inherit;
                top: 20px;
                background-color: transparent;
                display: flex;
                justify-content: center;
                opacity: 0;
                visibility: hidden;

                &>div {
                    border: 1px solid #ababab;
                    background-color: white;
                    border-radius: 2px;
                    width: 90%;


                    &>span {
                        font-size: .65rem;
                    }
                }
            }
        }

        & .img {
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 0;

            &>img {
                display: block;
            }
        }

        & .stats {
            font-size: .65rem;
            background-color: $amanin_logo_color;
            // height: calc(100% - 0px);
            display: flex;
            align-items: center;
            animation: fadeIn 2s;

            &>div {
                display: flex;
                flex-direction: column;
                width: 100%;

                &>div {
                    background-color: white;
                    border-radius: 0px 2px 2px 0px;
                    margin: 2px 2px 0px 2px;
                    display: flex;
                    white-space: nowrap;
                    align-items: center;
                    justify-content: flex-start;
                    transition: .2s;

                    &>img {
                        margin-right: 2px;
                        margin-left: 2px;
                    }

                    &>span {
                        white-space: nowrap;
                    }

                    &:hover {
                        transition: .2s;
                        cursor: pointer;
                        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
                    }
                }
            }
        }

        &>video {
            display: none;
            width: 100%;
            z-index: 1;
        }
    }

    &_t {
        display: flex;
        flex-direction: column;
        border: 1px solid #ababab;
        border-top: 0px;
        border-radius: 0px 0px 5px 5px;

        &.warning {
            border: 1px solid #dc143c;
            border-top: 0px;
        }

        & div>span {
            display: flex;
            padding: 1px 0;
        }

        &_i {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 0.65rem;
            font-weight: bold;
        }

        &_n {
            display: inline-flex;
            font-size: 0.65rem;
            justify-content: center;
            align-items: center;
            text-align: center;
        }

        &_w {
            display: inline-flex;
            font-size: 0.65rem;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: calc(100% - 4px);
            background-color: #dc143c;
            color: white;
            border: 1px solid #dc143c;
            border-radius: 0px 0px 4px 4px;
            padding: 2px;

            & span {
                background-color: white;
                color: rgb(92, 40, 40);
                font-weight: bold;
                padding-left: 2px;
                padding-right: 2px;
                margin-right: 2px;
                border-radius: 2px 2px 2px 2px;
            }
        }

        &:hover {
            cursor: pointer;
        }
    }
}

.status {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    &>div {
        background-color: rgb(164, 69, 69);
        padding: 2px;
        margin: 0px auto;
        align-items: center;
        text-align: center;
        justify-content: center;
        border-radius: 0px 0px 5px 5px;
        color: white;
        font-size: .65rem;
        font-weight: bold;
    }
}

.responsetime {
    background-color: white;
}

.configuration {
    // border: 1px solid red;
    display: flex;
    position: absolute;
    top: -3px;
    right: -10px;
    z-index: 3;
    transition: opacity 0.5s ease, visibility 0.5s ease;

    &_modal {
        transition: opacity 0.5s ease, visibility 0.5s ease;
        visibility: hidden;
        opacity: 0;
        display: flex;
        // flex-wrap: wrap;
        position: absolute;
        background-color: white;
        padding: 3px;
        top: 24px;
        right: 0px;
        width: 150px;
        height: 200px;
        border-radius: 5px 0px 5px 5px;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
        flex-direction: column;
        overflow-y: auto;

        &>div {
            display: flex;
            align-items: center;
            padding-top: 2px;
            padding-bottom: 2px;
            // justify-content: center;
            border-bottom: 1px solid rgba(255, 0, 0, 0.273);
            transition: all ease .5s;

            &:hover {
                cursor: pointer;
                background-color: #ababab58;
            }

            &>span {
                font-size: .7rem;
                // border:1px solid red;
                text-align: left;
                padding-left: 3px;
            }

            &>img {
                transition: 1s;
                border: 1px solid white;
                border-radius: 5px;
                margin: 1px;

                &:hover {
                    transition: .5s;
                    cursor: pointer;
                    border-color: rgba(71, 53, 29, 0.5);
                }
            }
        }
    }
}

.mobilepairing {
    // border:3px solid red;
    // width: 100%;
    display: flex;
    flex-direction: row;

    &>div {
        flex-grow: 1;
        // border:2px solid blue;
        // color: green;

        &>video {
            // border:2px solid green;
            // background-color: green;
            height: 100%;
            width: 100%;
        }
    }
}

.video {
    display: none;
}