@import "../../assets/scss/common.scss";

.login_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background: linear-gradient(30deg, #2a6dda, #63879a, #2a87a1, #92a7de);
    background-size: 300% 300%;
    animation: gradientShift 15s ease infinite;
}

@keyframes gradientShift {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.login_button {
    padding: 15px 30px;
    font-size: 18px;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.3);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.login_button:hover {
    background-color: rgba(0, 0, 0, 0.5);
    transform: scale(1.05);
}

.scanner_container {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
    /* Adjust the background color as needed */
}

.qr_code_scanner {
    text-align: center;
    padding: 10px;
    width: 100%;

    h2 {
        font-size: 1.5em;
        margin-bottom: 20px;
        color: #fff;
    }

    .qr_reader_container {
        width: 100%;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        overflow: hidden;
    }

    .qr_video_wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .qr_video_wrapper video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1.3);
    }

    p {
        margin-top: 20px;
        font-size: 1.2em;
        color: #fff;
        word-wrap: break-word;
    }
}

/* Media queries for different mobile screen sizes */
@media (max-width: 575.98px) {
    .qr_code_scanner {
        padding: 5px;

        h2 {
            font-size: 1.2em;
        }

        p {
            font-size: 1em;
        }
    }
}

@media (max-width: 767.98px) {
    .qr_code_scanner {
        padding: 8px;

        h2 {
            font-size: 1.3em;
        }

        p {
            font-size: 1.1em;
        }
    }
}

@media (max-width: 991.98px) {
    .qr_code_scanner {
        padding: 10px;

        h2 {
            font-size: 1.4em;
        }

        p {
            font-size: 1.1em;
        }
    }
}

@media (max-width: 1199.98px) {
    .qr_code_scanner {
        padding: 15px;

        h2 {
            font-size: 1.5em;
        }

        p {
            font-size: 1.2em;
        }
    }
}