@import "../../../assets/scss/common.scss";

.container {
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 101;
    position: relative;
    background-color: $amanin_logo_color;
    height: 100vh;
    font-family: $amanin_font_family;
    overflow-y: auto;

    @include responsiveMobileMedium() {
        width: 100%;

        h2 {
            font-size: 1.2rem;
        }
    }

    &_profile {
        margin: 5px;
        display: flex;
        align-items: center;
        justify-content: center;



        &_info {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 10px;
            background-color: rgba(255, 255, 255, 0.4);
            padding: 10px;
            border-radius: 5px;
            font-size: .85rem;
            max-width: 700px;


            &>img {
                height: 50px;
            }

            & .example {
                margin-left: 10px;
                display: flex;
                flex-direction: column;

                &>img {
                    height: 150px;
                }

                &>span {
                    font-size: .70rem;
                    text-align: center;
                    font-style: italic;
                    font-weight: bold;
                }
            }
        }


        @include responsiveMobileStandard() {
            flex-direction: column;
            gap: 5px;



            img {
                width: 100px;
                height: auto;
            }

            &_info {
                transform: scale(.95);
                font-size: .75rem;

                .example {
                    place-items: center;

                    span {
                        font-size: .65rem;
                    }

                    img {
                        height: auto;
                        width: 120px;

                        // transform: scale(.8);
                    }
                }
            }


        }

        @include responsiveMobileMedium() {
            flex-direction: column;
            transform: scale(.85);
            order: 999;

            .identity {
                display: none;
            }

            img {
                width: 100px;
                height: auto;
            }

            &_info {
                font-size: .7rem;

                img {
                    width: 150px;
                    height: auto;
                    transform: scale(.7);
                }
            }
        }

        @include responsiveMobileSmall() {
            flex-direction: column;
            transform: scale(.65);
            order: 999;
        }


    }

    &_bordervideo {
        padding: 3px;
        background-color: black;
        border-radius: 5px;
        margin: 10px;

        @include responsiveMobileMedium {
            transform: scale(.7);
            margin: -20px;

        }

        @include responsiveMobileSmall {
            transform: scale(.6);
            margin: 0;
            margin: -20px;
            // border: 1px solid red;
        }
    }

    &_video {
        display: flex;
        position: relative;
        z-index: 100;

    }

    &_robot {
        background-color: rgba(26, 26, 26, 0.3);
        color: white;
        width: 500px;
        min-height: 100px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 5px;
        border-radius: 10px;

        &>span {
            margin-left: 5px;
            padding-left: 5px;
        }

        @include responsiveMobileStandard {
            transform: scale(.9);

        }

        @include responsiveMobileMedium {
            transform: scale(.8);
        }

        @include responsiveMobileMedium {
            transform: scale(.75);
        }

    }

    &_progress {
        margin: 0 auto;
        margin: 5px;
        border-radius: 3px;
        background-color: rgba(255, 255, 255, 0.5);

        // border:1px solid $amanin_logo_color2;
        // padding: 2px;
        @include responsiveMobileMedium {
            transform: scale(.9);
        }
    }

    &_debug {
        border: 2px solid red;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &_cap {
            border: 2px solid red;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            &>img {
                border: 2px solid blue;
            }
        }

        &_info {
            display: flex;
            flex-direction: column;
        }
    }


}

.box {
    position: absolute;
    width: 100%;
    height: 100%;

    &_top {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        background-color: rgba(255, 255, 255, 0.9);
    }

    &_bottom {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 40px;
        background-color: rgba(255, 255, 255, 0.9);
    }

    &_left {
        position: absolute;
        left: 0;
        top: 40px;
        width: 25%;
        height: 160px;
        background-color: rgba(255, 255, 255, 0.9);
    }

    &_right {
        position: absolute;
        right: 0;
        top: 40px;
        width: 25%;
        height: 160px;
        background-color: rgba(255, 255, 255, 0.9);
    }
}

.video_message {
    // border:2px solid red;
    display: flex;
    flex-direction: row;
}

.messages {
    justify-content: center;
    align-items: center;
    // border:2px solid blue;
    display: flex;
    flex-direction: column;
}