@import "../assets/scss/common.scss";

.container {
    display: flex;
    height: 100vh;
    background-color: #272626;
    color: white;
    font-family: $amanin_font_family;
    justify-content: center;
    align-items: center;
    font-size: .9rem;
    &_c {
        border:1px solid white;
        min-width: 600px;
        max-width: 700px;
        min-height: 600px;
        max-height: 700px;
        border-radius: 10px;

        &_title {
            display: flex;
            border-bottom: 1px solid white;
            align-items: center;
            font-weight: bold;

            &>img {
                margin: 10px;
                width: 70px;
            }
            &>span {
                font-size: 1.3rem;
            }
        }

        &_bottom {
            padding: 5px;
        }
    }
}