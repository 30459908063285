@import "../../assets/scss/common.scss";

.form_container {
    display: flex;
    font-size: .75rem;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    overflow-y: auto;

    &_title {
        display: flex;
        font-weight: bold;
        align-items: center;
        margin-top: 10px;
        font-size: .8rem;
    }

    &_c {
        display: flex;
        width: 100%;
        justify-content: center;

        &_form {
            display: flex;
            flex-direction: column;
            border: 1px solid white;
            border-radius: 5px;
            background-color: white;
            margin: 1px;
            padding: 10px;
        }
    }

    .inputfield {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 5px;
        margin: 1px;

        &>label {
            margin: 3px 3px 3px 2px;
        }

        &>input[type="text"] {
            font-size: .75rem;
            width: 100%;
            min-width: 300px;
            padding: 5px 5px;
            display: inline-block;
            border: 1px solid #ccc;
            border-radius: 4px;
            box-sizing: border-box;
            background-color: white;
        }

        &>input[type="number"],
        &>input[type="datetime-local"],
        &>input[type="time"],
        &>input[type="date"] {
            font-size: .75rem;
            padding: 5px 5px;
            display: inline-block;
            border: 1px solid #ccc;
            border-radius: 4px;
            box-sizing: border-box;
            background-color: white;
        }

        &>textarea {
            width: 100%;
            /* Atur sesuai kebutuhan */
            height: auto;
            max-height: 7em;
            /* 7 lines worth of height, adjust as needed */
            overflow-y: auto;
            /* Enable scrollbar when content exceeds max height */
            resize: none;
            /* Prevent manual resizing */
        }

        & .custom_select {
            &>select {
                font-size: .75rem;
                padding: 5px 5px;
                display: inline-block;
                border: 1px solid #ccc;
                border-radius: 4px;
                box-sizing: border-box;
                background-color: white;
            }
        }
    }

    .statement {
        display: inline-flex;
        width: 300px;
    }

    .checkbox {
        display: flex;
        margin: 5px;
        justify-content: center;
        align-items: center;
        padding: 5px;

        &>span {
            margin-left: 5px;
        }
    }

    .back {
        margin: 5px;
        font-weight: normal;
        font-size: .75rem;
        padding: 5px;
    }

    .btnOk {
        text-align: center;
        margin-top: 10px;
    }
}