.menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    // border:1px solid red;
    &>div {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px;
        margin: 3px;
        transition: .3s ease;
        border-radius: 5px;

        &.xclose {
            margin-top: 0px;
            width: calc(100% - 14px);
            border-radius: 0px;
            display: flex;
            flex-direction: column;
            border-bottom: 2px solid rgba(0, 0, 0, 0.5);

            &>span {
                font-size: .7rem;
                color: white;
                font-weight: bold;
            }

            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
                border-top-left-radius: 10px;
                cursor: pointer;
            }
        }

        &.xmenu {
            &:hover {
                cursor: pointer;
                background-color: rgba(133, 133, 133, 0.5);
            }

            &.chosen {
                background-color: rgba(255, 255, 255, 0.8);
            }
        }
    }
}