@import "../../assets/scss/common.scss";

.container {
    position: relative;
    background-color: rgb(48, 60, 87);
    border-radius: 5px;
    width: calc(280px - 10px);
    padding: 5px;
    font-size: $amanin_font_size;
    font-family: $amanin_font_family;
    display: flex;
    flex-direction: column;
    text-align: center;

    &.big {
        width: calc(100% - 10px);
        height: calc(100% - 10px);
    }

    &>span {
        color: white;
        margin-bottom: 3px;
        font-size: .7rem;
        font-weight: bold;
    }

    &_room {
        position: relative;
        background-color: rgb(237, 229, 213);
        font-size: 0.65rem;
        height: 250px;
        padding-left: 2px;
        padding-right: 2px;
        overflow: auto;

        display: flex;
        flex-direction: column-reverse;

        scroll-behavior: smooth;
        transition: scroll-behavior 0.5s ease-in-out;

        &.big {
            // border:2px solid red;
            height: 100%;
        }

        & .chat {
            display: flex;
            font-size: 0.65rem;
            align-items: center;
            justify-content: flex-start;
            padding: 1px;

            &_ts {
                color: blue;
                margin-right: 2px;
                font-size: .65rem;
                width: 50px;
            }

            &_from {
                color: red;
                margin-right: 2px;
                padding: 2px;

                &:hover {
                    cursor: pointer;
                    background-color: #456885;
                    color: white;
                    border-radius: 3px;
                }

            }

            &_text {
                color: black;
                text-align: left;
            }
        }
    }

    &_input {
        display: flex;
        border-top: 2px solid rgba(0, 0, 0, 0.5);
        height: 30px;
        background-color: white;
        align-items: center;
        justify-content: space-between;

        &.big {
            height: 40px;
        }

        &>input[type=text] {
            border: none;
            outline: none;
            width: 100%;
            padding-left: 3px;
            padding-right: 3px;
            font-size: .75rem;

            &.big {
                font-size: .85rem;
                // border: 1px solid red;
            }

        }

        &>button {
            margin-right: 3px;
        }
    }
}

.validation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &_btn {
        display: flex;
        align-items: center;
        padding-left: 3px;
        padding-right: 3px;
        padding-top: 3px;
        padding-bottom: 3px;
        border-radius: 3px;

        &:hover {
            cursor: pointer;
            background-color: rgba(202, 215, 167, 0.402);
        }
    }
}

.send {
    margin-right: 2px;
    border-radius: 3px;
    margin-bottom: 1px;
    margin-top: 1px;

    &:hover {
        cursor: pointer;
    }

    @media only screen and (max-width: 500px) {

        img {
            width: 20px;
        }
    }
}

.bubbler {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    &_text {
        &_triangle {
            position: absolute;
            width: 0px;
            height: 0px;
            right: -9px;
            top: -0px;
            border-top: 10px solid #f8fff7;
            border-right: 10px solid transparent;
        }
    }
}

.bubble {
    display: flex;
    width: 100%;

    &_text {
        display: inline-flex;
        position: relative;
        flex-direction: column;
        color: black;
        max-width: 200px;

        word-wrap: break-word;
        margin: 2px 10px 2px 10px;
        padding: 3px;
        border-radius: 0px 0px 5px 5px;
        box-shadow: 1px 1px rgba(0, 0, 0, 0.1);

        &.big {
            max-width: 80%;
        }

        &_h {
            font-weight: bold;
            font-size: .60rem;
            color: rgb(50, 151, 205);
            text-align: left;
            margin-bottom: 2px;

            &.big {
                font-size: .70rem;
            }

            &:hover {
                cursor: pointer;
                color: rgb(90, 35, 35);
            }
        }

        &_c {
            text-align: left;
            margin-left: 5px;

            &.big {
                font-size: .75rem;
            }
        }

        &_t {
            margin-top: 5px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            justify-content: flex-end;

            &_t {
                text-align: right;

                font-size: .55rem;
                color: rgb(255, 0, 115);

                font-style: italic;
                margin-right: 1px;

                &.big {
                    font-size: .65rem;
                }
            }
        }

        &_triangle {
            position: absolute;
            width: 0px;
            height: 0px;
            left: -9px;
            top: -0px;
            border-top: 10px solid white;
            border-left: 10px solid transparent;
        }
    }
}

.tick {
    width: 10px;
}

.newmessage {
    position: absolute;
    background-color: white;
    border: 1px solid rgb(184, 176, 176, 0.7);
    bottom: 50px;
    right: 20px;
    z-index: 101;
    width: 40px;
    height: 40px;
    border-radius: 50px;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
        background-color: rgb(227, 235, 249);
        transition: .2s;
    }

    &>img {
        margin-top: 2px;
        height: 25px;
    }
}