@import "../assets/scss/common.scss";

.troubleshooting {
    margin-left: 10px;
    display: flex;
    position: relative;
    font-size: .75rem;
    // color: white;
    color: #151515;
    transition: .2s;

    &_title {
        display: flex;
        justify-content: center;
        // background-color: rgb(23, 47, 72);
        align-items: center;
        justify-content: center;
        // padding-left: 3px;
        // padding-right: 3px;
        // border-radius: 5px;
        // transition: .2s;

        &>img {
            margin-right: 3px;
            // padding-top: 2px;
            height: 30px;
        }


        &:hover {
            // transition: .2s;
            // cursor: pointer;
            // background-color: rgba(23, 47, 72, 0.5);
        }
    }

    &_content {
        min-width: 250px;
        max-width: 200px;
        max-height: 500px;
        position: absolute;
        top: 39px;
        background-color: #272626;
        font-weight: normal;
        left: -50px;
        border-radius: 5px;
        justify-content: flex-start;
        z-index: 2001;

        &>div {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            background-color: rgb(40, 126, 110);
            padding: 10px;
            margin: 3px;
            transition: .2s;

            &>span {
                text-align: left;
            }

            &:hover {
                transition: .2s;
                cursor: pointer;
                color: black;
                background-color: rgb(133, 185, 109);
            }
        }

        & .img {
            z-index: 2001;
            position: absolute;
            background-color: rgb(40, 126, 110);
            left: 250px;
            top: -39px;
            min-width: 400px;
            overflow-y: auto;
            border: 2px solid rgb(40, 126, 110);
            max-width: calc(100vw - 350px);
            height: calc(100vh - 50px);
        }
    }
}