@import "../../assets/scss/common.scss";

.container {
    &_slash {
        border: 2px solid rgba(0, 0, 0, 0.2);
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 5px;
        position: relative;
        z-index: 2;
        color: rgb(58, 58, 58);

        &:hover {
            cursor: pointer;
            border: 2px solid rgba(147, 24, 158, 0.9);
        }
    }

    &_content {
        position: fixed;
        background-color: rgb(45, 43, 43);
        z-index: 1300;
        transition: opacity 0.5s ease, visibility 0.5s ease;
        height: 90%;
        right: 100px;

        &_search {
            border: 1px solid rgba(255, 255, 255, .1);
            display: flex;

            &>img {
                width: 20px;
                padding: 3px;
                margin-top: 3px;
                margin-bottom: 3px;
                margin-right: 5px;
                margin-left: 5px;
                background-color: rgba(255, 255, 255, 0.5);
                border-radius: 50px;
            }

            &>input[type="text"] {
                color: white;
                background-color: rgb(45, 43, 43);
                width: 100%;
                outline: none;
                border: none;
                padding: 3px 5px;
                font-size: .75rem;
            }

            &>div {
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                margin-right: 5px;

                &:hover {
                    cursor: pointer;
                    color: rgb(29, 209, 194);
                }
            }
        }

        &_list {
            border-radius: 5px;
            width: 500px;
            height: calc(100% - 30px);
            background-color: rgb(45, 43, 43);
            overflow-y: auto;
            position: relative;

            .topicHeader {
                color: white;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 1rem;

                padding: 10px;
                /* Add padding for better spacing */

            }

            & .item {
                display: flex;
                margin: 5px;
                transition: .5s;

                &:hover {
                    cursor: pointer;
                    transition: .5s;
                    background-color: rgba(189, 116, 226, 0.3);
                }

                &>div {
                    position: relative;
                    padding: 5px;
                    border-radius: 3px;
                    color: white;
                    background-color: rgba(255, 255, 255, .1);

                    &.code {
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        text-align: left;

                        .infoContainer {
                            position: relative;
                            display: inline-block;

                            .infoIcon {
                                width: 25px;
                                height: 25px;
                                margin-top: 5px;
                                cursor: pointer;


                            }

                            .tooltip {
                                position: absolute;
                                z-index: 9999;
                                left: 50%;
                                transform: translateX(20%) translateY(-80%);
                                width: 250px;
                                display: none;
                                background-color: #aaa;
                                color: #181010;
                                text-align: left;
                                font-weight: 600;
                                border-radius: 5px;
                                padding: 10px;
                                transition: opacity 0.3s;

                                &:before {
                                    content: "";
                                    position: absolute;
                                    top: 50%;
                                    right: 100%;
                                    transform: translateY(-50%);
                                    border-width: 5px;
                                    border-style: solid;
                                    border-color: transparent #aaa transparent transparent;
                                }
                            }

                            &:hover .tooltip {
                                visibility: visible;
                                opacity: 1;
                                display: block;

                            }

                        }

                    }

                    &.content {
                        flex-grow: 1;
                        margin-left: 2px;
                        text-align: left;
                    }

                    &.action_icon {
                        // flex-grow: 1;
                        margin-left: 2px;
                        // text-align: left;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        column-gap: 10px;
                    }
                }
            }
        }
    }
}

// .container_modal {
//     position: absolute;
//     height: 100vh;
//     width: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     background-color: rgba(0, 0, 0, 0.5);
//     z-index: 101;

//     &_content {
//         width: 400px;
//         border: 1px solid #545454;
//         background-color: white;
//         border-radius: 10px;
//         padding: 10px;
//         box-shadow: 3px 3px 7px 3px rgba(0, 0, 0, 0.5);
//         animation: fadeIn 1.0s;
//     }
// }