@import "../assets/scss/common.scss";

.alert {
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    margin: 1rem;
    width: 100%;
    text-align: center;
    font-weight: 700;

    &_danger {
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
    }

    &_success {
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
    }

}

