@import "../../assets/scss/common.scss";

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

textarea {
    font-family: Arial, Helvetica, sans-serif;
}

/* Modal */
.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    font-family: $amanin_font_family;
    font-size: .75rem;
    z-index: 2;
    transform: scale(0.9);
    transition: width 3s, height 3s;
}

.modal_container {
    max-width: 1200px;
    min-width: 200px;
    min-height: 200px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    background-color: #ffffff;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
    border-radius: 8px;
    font-size: .75rem;
    overflow: auto;
}

.modal {
    display: flex;
    flex-direction: column;
    width: 100%;
    transition: opacity 0.3s ease-in-out, visibility 0s linear 0.3s;
    align-items: center;
    justify-content: center;
    padding-bottom: 5px;
    padding-top: 5px;

    &_w {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 600px;
        font-size: .7rem;
        background-color: rgb(39, 104, 119);
        border: 1px solid rgb(119, 102, 21);
        padding: 5px;
        border-radius: 5px;
        flex-direction: column;

        color: white;

        &>div {
            display: flex;
            align-items: center;

            &.i {
                font-size: .65rem;
                font-style: italic;
                text-align: center;
            }
        }
    }

    &_c {
        display: flex;

        &_profile {
            display: flex;
            flex-direction: row;

            &_left {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;

                &_show {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    & .profile {
                        background-color: rgb(200, 227, 228);
                        border: 1px solid rgb(112, 148, 183);
                        text-align: left;
                        font-size: .75rem;
                        min-width: 290px;
                        margin-left: 5px;
                        margin-right: 5px;
                        margin-top: 5px;
                        margin-bottom: 5px;
                        border-radius: 5px;
                    }
                }
            }

            &_right {
                display: flex;
                flex-direction: column;
                text-align: center;
                margin: 5px;
                border-radius: 5px;
                color: white;
                align-items: center;
                justify-content: flex-start;

                &>span {
                    padding-bottom: 3px;
                    color: black;
                }

                .direction {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 2px solid rgb(110, 145, 227);
                    margin: 2px;
                    color: black;
                    padding: 2px;
                    white-space: nowrap;

                    &:hover {
                        background-color: rgba(110, 145, 227, 0.54);
                        cursor: pointer;
                    }
                }

                .active {
                    background-color: rgba(110, 145, 227, 0.54);
                }

                .chat_container {
                    background-color: $amanin_logo_color;
                }

                &_photos {
                    display: flex;
                    align-items: flex-start;
                    font-size: .75rem;
                    animation: fadeIn 2.5s;

                    &>div {
                        margin: 3px;
                        padding: 5px;
                        border-radius: 5px 5px 0px 0px;
                        background-color: rgb(82, 80, 80);
                        color: white;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        font-weight: bold;

                        &>span {
                            margin-bottom: 3px;
                        }

                        &.facevalidated {
                            & .sim {
                                margin: 3px 3px 3px 3px !important;
                                padding: 3px 5px 3px 5px;
                                border-radius: 5px;
                                background-color: rgb(28, 28, 28);
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
        }


        &_button {
            margin: 5px;
            display: flex;

            &>button {
                margin: 10px;
            }
        }
    }

}

.close_btn {
    position: fixed;
    top: 0px;
    right: 15px;
    cursor: pointer;
    font-weight: 600;

    &:hover {
        color: red;
    }
}

.screenshot_card_wrapper {
    display: flex;
    flex-wrap: wrap;
    border: 2px solid rgb(160, 170, 185);
    border-radius: 5px;
    height: 450px;
    width: 700px;
    overflow-y: auto;
    margin: 5px;
    justify-content: space-around;

    &::-webkit-scrollbar {
        width: .3rem;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 3px;
        background: #777fae;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }

}

.bold {
    font-weight: 600;
}

@media screen and (max-width: 500px) {
    .modal_container {
        flex-direction: column;
        top: 0;
        left: 0;
        transform: none;
        width: 100%;
        height: 100vh;
    }

    img {
        width: 100%;
        max-height: 70vh;
        object-fit: cover;
    }

    .heading {
        margin: 1rem;
    }
}