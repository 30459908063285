@import "../../../assets/scss/common.scss";

.container {
    // border:2px solid red;
    color: white;
    height: calc(100vh - 120px);
    background-color: rgba(221, 195, 139, 0.1);
    display: flex;
    font-size: .75rem;
    // transition: all ease 1s;

    &_first {
        min-width: 320px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        overflow-y: auto;
        // border:1px solid green;
    }

    &_second {
        margin: 0 20px 20px 20px;
        width: 100%;

        &_wrap {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-inline: auto;

            textarea {
                width: 100%;
                max-width: 100%;
                box-sizing: border-box;
                resize: none;
                padding: 20px;
            }
        }


    }
}