@import "../../../assets/scss/common.scss";

$border_outer_color: rgba(43, 44, 45, 255);
$border_color: rgba(43, 44, 45, 255);
// $menu_color: rgba(40, 40, 41, 255);
$menu_color: rgb(80, 80, 80);
$body_color: #1E201E;
$background_color: rgba(141, 141, 141, 0.8);
$participant_color: rgba(30, 30, 30, 255);
$content_color: rgba(24, 25, 25, 255);
$separator: rgba(199, 226, 158, 0.5);

.container {
    position: fixed;
    // border:10px solid red;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background-color: $background_color;
    display: flex;

    &_body {
        flex-grow: 1;
        margin: 1%;
        border-radius: 10px;
        display: flex;
        // border:5px solid green;
        width: calc(100% - 0px);
        height: calc(100vh - 30px);

        &_menu {
            width: 80px;
            border-right: 1px solid $border_color;
            background-color: $menu_color;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }


        &_participant {
            display: flex;
            flex-direction: column;
            border-right: 1px solid $border_color;
            background-color: $participant_color;
            // border:1px solid red;

            &_search {
                // border: 1px solid red;
            }

            &_par {
                // border:1px solid green;
                overflow-y: auto;
                overflow-x: hidden;
            }
        }

        &_content {
            // border:2px solid red;
            // overflow-x: auto;
            // overflow-y: auto;
            display: flex;
            flex-direction: column;
            width: 100% !important;
            background-color: $content_color;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;

            &_header {
                // border:1px solid red;
                height: 90px;
                border-top-right-radius: 5px;
                background-color: rgba(255, 255, 255, 0.1);
                display: flex;
                align-items: center;
            }

            &_body {
                // border:2px solid blue;
                width: 100%;
                // height: 100%;
                flex-grow: 1;
                // overflow-y: auto;
            }
        }
    }
}