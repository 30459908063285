.terms {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1102;

    &>div {
        margin: 10px auto;
        max-width: 840px;
        height: 800px;

        &>iframe {
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }
    }
}