@import "../assets/scss/common.scss";
.center {
    justify-content: center !important;
    align-items: center !important;
}

.left {
    align-items: flex-start !important;
    justify-content: center !important;
}
.container {
    display: flex !important;
    flex-direction: column !important;
    border: 1px solid $amanin_logo_color;
    font-family: $amanin_font_family;
    font-size: .75rem;
    min-width: 400px;
    height: 30px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.34);

    &_funny {
        display: flex;
        width: calc(100% - 20px);
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        &_width {
            display: inline;
        }
        &_dot {
            display: flex;
            width: 10px;
            height: 10px;
            border-radius: 10px;
            background-color: rgb(100, 100, 179);
        }
    }
    &_first {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 50%;
        background-color: rgba(12, 136, 193, 0.9);
        height: 10px;
        &>span {
            background-color: white;
            border: 1px solid #ababab;
            border-radius: 5px;
            padding: 2px;
            font-weight: bold;
            font-size: .65rem;
        }
    }
}

.animateme {
    animation-name: funloading;
    animation-duration: 5s;
    animation-iteration-count: infinite;
}

@keyframes funloading {
    0% {
        width: 0%;
    }
    50% {
        width: calc(100% - 10px);
    }
    100% {
        width: 0%;
    }
}