@import "../../../assets/scss/common.scss";

.container {
    height: 100%;
    display: flex;
    color: white;
    font-size: .75rem;

    &>div {
        &.left {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 5px;
            &_title {
                // border:2px solid red;
            }

            & .img {
                max-width: 320px;
                // height: 240px;

                &>img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
            }

            &>video {
                max-width: 320px;
                max-height: 240px;
                background-color: rgba(255, 255, 255, 0.1);
            }

            &>span {
                padding: 10px;
                font-weight: bold;
            }

            & .audio {
                margin: 5px;
                width: 30px;
                height: 30px;
                background-color: white;
                border-radius: 5px;
                transition: all ease .5s;

                &>img {
                    width: 100%;
                }
                &:hover {
                    cursor: pointer;
                    background-color: rgba(223, 243, 143, 1);
                }
            }
        }

        &.right {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 5px;

            & .screen {
                display: flex;
                flex-direction: column;
                border-bottom: 1px solid rgb(48, 48, 48);
                flex-grow: 1;
                align-items: center;
                overflow-x: auto;
                height: 50%;

                &_title {
                    display: flex;
                    align-items: center;
                    &>span {
                        font-weight: bold;
                        padding: 0px 10px 0px 10px;
                    }

                    &_reconnect {
                        align-items: center;
                        display: flex;
                        padding: 0px 5px 0px 0px;

                        
                        &>img {
                            height: 20px;
                            padding: 2px 5px 2px 5px;
                        }
                    }
                }
            }

            & .mp {
                flex-grow: 1;
            }
        }
    }
}

.horiz {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 10%;
    overflow-y: auto;

    &>div {
        display: flex;
        flex-grow: 1;
        align-items: start;
        justify-content: start;
        width: 50%;

        &.img {
            display: flex;
            align-items: center;
            &>img {
                margin: 0 auto;
                width: 100%;
                object-fit: contain;
            }
        }

        &>video {
            height: 100%;
            width: 100%;
        }
    }
}