@import "../../assets/scss/common.scss";

.container {
    display: flex;
    height: 100vh;
    font-family: $amanin_font_family;
    overflow: auto;

    &_conmsg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        flex-direction: column;

        &_progress {
            margin: 5px;
        }

        &_content {
            display: inline-flex;
            height: 50px;
            background-color: rgba(255, 255, 255, 0.8);
            justify-content: center;
            padding: 10px;
            align-items: center;
            font-weight: bold;
            border-radius: 5px;
        }
    }

    &_left {
        display: flex;
        flex-grow: 1;
        flex-direction: column;

        &_top {
            display: flex;
            font-size: 1.5rem;
            font-weight: bold;
            width: 100%;
            text-align: center;
            height: 40px !important;
            margin-bottom: 1px;
            justify-content: space-between;
            align-items: center;

            &>div {
                display: flex;
                align-items: center;

                &>div.title {
                    display: flex;
                    flex-direction: column;

                    &>span {
                        &.big {
                            font-size: 1.0rem;
                        }

                        &.snall {
                            font-size: 0.8rem !important;
                            font-weight: bold;
                        }
                    }
                }

                &>img {
                    margin-left: 5px;
                    background-color: rgba(255, 255, 255, 1);
                    padding: 5px;
                    border-radius: 5px;

                    &.itb {
                        padding: 2px !important;
                    }
                }
            }
        }

        &_bottom {
            display: flex;
            flex-direction: column;
            height: calc(100% - 40px);
            margin: 0px 5px 5px 5px;
            border-radius: 5px;
            background-color: white;

            &_c {
                font-family: $amanin_font_family;
                font-size: 0.9rem;
                display: flex;
                justify-content: center;
                padding: 5px;
                align-items: center;

                &>div {
                    display: flex;
                    font-size: 0.75rem;
                    margin-left: 5px;
                    margin-right: 5px;
                    align-items: center;

                    & .c {
                        display: flex;
                        position: relative;

                        &_btn {
                            display: flex;
                            background-color: #caebd0;
                            /* Green */
                            border: 1px solid rgba(71, 146, 117, 0.503);
                            color: white;
                            border-radius: 5px;
                            text-align: center;
                            text-decoration: none;
                            align-items: center;
                            justify-content: center;
                            width: 27px;
                            height: 27px;

                            &:hover {
                                background-color: #d6f7dc;
                                cursor: pointer;
                            }
                        }
                    }
                }

                &>span {
                    margin-left: 5px;
                    margin-right: 5px;
                }

                &>button:hover {
                    cursor: pointer;
                }
            }

            &_p {
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                align-items: center;
                overflow-y: auto;
                padding-top: 10px;
            }
        }
    }

    &_right {
        position: relative;
        margin-right: 5px;

        &_notif {
            display: flex;
            justify-content: flex-end;
            margin: 20px 20px 0 0;

            button {
                cursor: pointer;
                border-radius: 50%;
                border-color: tomato;
                color: tomato;
                width: 30px;
                height: 30px;
            }
        }
    }
}

.control {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    transition: width .5s;
    z-index: 100;

    &_toggle {
        position: absolute;
        display: flex;
        justify-content: center;
        font-weight: bold;
        align-items: center;
        width: 28px;
        height: 28px;
        margin: 5px 4px 5px 4px;
        background-color: white;
        left: -40px;
        top: -0px;
        border-radius: 5px 0px 0px 5px;
        border: 1px solid white;

        &>img {
            display: inline-flex;
            border-radius: 5px;
            transition: transform .5s;
        }

        &:hover {
            cursor: pointer;
            border: 1px solid #5b5b5b;
        }

    }

    &_timer {
        display: flex;
        height: 28px;
        min-width: 250px;
        background-color: white;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        border: 1px solid white;
        margin: 5px 0px 5px 0px;
    }

    &_examTime {
        display: flex;
        border: 1px solid red;
        background-color: white;
        align-items: center;
        justify-content: center;
        font-size: .8rem;
        padding: 5px;
        border-radius: 5px;
        font-weight: bold;
    }

    &_profile {
        display: flex;
        min-width: 250px;
        flex-direction: column;
        background-color: white;
        border-radius: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
        line-height: 1.5em;
        text-align: center;
        margin-top: 1px;
        font-size: .7rem;
    }

    &_webcam {
        margin-top: 5px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 5px;
        width: 280px;
        height: 220px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    &_webcamsmall {
        width: 70px;
        height: 70px;
        padding: 0px;
    }

}

.container_broadcastProc {
    font-size: .75rem;
    margin-top: 2px;
    border: 2px solid #303c57;
    border-radius: 5px;
    min-width: 250px;

    &_tittle {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #c83030;
        padding: 2px;
        color: white;
        font-weight: bold;
        border-radius: 3px 3px 0 0;
    }

    &_message {
        background-color: white;
        padding: 5px;
        max-height: 100px;
        /* Adjust this as needed */
        overflow-y: auto;
        overflow-wrap: break-word;
    }

    &_input {
        border-top: 2px solid rgba(0, 0, 0, 0.5);
        background-color: white;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        border-radius: 0 0 5px 5px;

        &>textarea {
            flex-grow: 1;
            border: none;
            outline: none;
            font-size: .75rem;
            padding-left: 3px;
            padding-right: 3px;
            resize: none;
            overflow-y: auto;
            min-height: 20px;
            max-height: calc(6 * 20px);
            line-height: 20px;
            direction: ltr;
            text-align: left;
        }

        &>textarea::-webkit-scrollbar {
            width: 12px;
            /* Width of the scrollbar */
        }

        &>textarea::-webkit-scrollbar {
            width: 7px;
            height: 7px;
        }

        &>textarea::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            -webkit-border-radius: 3px;
            border-radius: 3px;
        }

        &>textarea::-webkit-scrollbar-thumb {
            -webkit-border-radius: 10px;
            border-radius: 3px;
            background: #777fae;
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        }

        .button_wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &>button {
            margin-right: 3px;
            margin-top: 3px;
            flex-shrink: 0;
            align-self: flex-end;
            border-radius: 100%;
            cursor: pointer;
            transition: background-color 0.3s ease;

            &:hover {
                filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.5));
                transform: scale(1.2);
            }
        }

    }
}


.pv {
    display: flex;
    padding-top: 10px;
}

.send {
    margin-right: 2px;
    border-radius: 3px;
    // border: 1px solid white;
    margin-bottom: 1px;
    margin-top: 1px;

    &:hover {
        cursor: pointer;
        // border: 1px solid #6d96b6ac;
    }
}

.alloff {
    display: flex;
    justify-content: center;
    padding: 20px;
    padding-top: 40px;
    align-items: center;

    &>div {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        padding: 5px;
        display: flex;
        background-color: #b4dce6;

        &>div {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            &>img {
                margin-right: 5px;
            }

            &>span {
                padding: 5px;
            }
        }
    }
}

.search {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .8rem;
    margin-top: 10px;

    &_info {
        font-size: .8rem;
        font-weight: bold;
    }

    &>div {
        background-color: rgb(89, 89, 173);
        color: white;
        padding: 5px;
        border-radius: 5px 0px 0px 5px;
    }

    &>input[type="text"] {
        border: 1px solid #ababab;
        border-left: 0;
        min-width: 50%;
        border-radius: 0 5px 5px 0;
        padding: 5px;
        outline: none;

        &:focus {
            box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
        }

        &::placeholder {
            font-size: .7rem;
            color: #ababab;
        }
    }
}

.searchbox {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 70px;

    & .box {
        position: absolute;
        background-color: white;
        border: 1px solid rgba(0, 0, 0, 0.3);
        padding-right: 2px;
        border-radius: 50px;
        font-size: .65rem;

        height: 30px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        transition: width 0.3s ease;

        &>input[type="text"] {
            font-size: .72rem;
            border: none;
            outline: none;
            width: 36px;
            transition: width 0.3s ease;

            &:focus {
                width: 140px;
                transition: width 0.3s ease;
            }
        }

        &>img {
            width: 20px;
        }
    }
}

.cmulticast {
    display: flex;
    position: relative;
    margin-top: 5px;
    width: 280px;
    &_btn {
        display: flex;
        background-color: #caebd0;
        /* Green */
        border: 1px solid rgba(71, 146, 117, 0.503);
        color: white;
        border-radius: 5px;
        text-align: center;
        text-decoration: none;
        align-items: center;
        justify-content: flex-start;
        // width: 27px;
        width: 100%;
        height: 27px;
        font-size: .7rem;
        color: grey;

        &:hover {
            background-color: #d6f7dc;
            cursor: pointer;
        }

        &>img {
            // border:1px solid black;
            margin-left: 5px;
            margin-right: 5px;
        }
    }
}

.multicast {
    position: absolute;
    right: 0px;
    top: 28px;
    z-index: 100;
    // box-shadow: 1px 1px 20px black;
    background-color: white;
    border-radius: 5px;
    border:1px solid black;


    &_tri {
        position: absolute;
        width: 0px;
        height: 0px;
        border-top: 18px solid #303c57;
        border-left: 18px solid transparent;
        left: -18px;
    }

    &>span {
        display: flex;
        font-size: .65rem;
        font-weight: bold;
        // background-color: #303c57;
        background-color: #000000;
        color: white;
        padding: 5px;
        text-align: center;
    }

    &_content {
        display: flex;
        flex-direction: row;
        min-width: 200px;
        align-items: center;
        // border:2px solid red;
        border-top: 0px;

        &>input[type=text] {
            border: none;
            outline: none;
            width: 100%;
            font-size: .75rem;
            padding-left: 3px;
            padding-right: 3px;
        }
    }
}