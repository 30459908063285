@import "../assets/scss/common.scss";

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center !important;
    margin-left: 10px;
    transition: .2s;
    height: auto;
    // border:1px solid white;

    &_title {
        position: absolute;
        font-size: .75rem;
        font-weight: normal;
        width: 100px;
        background-color: white;
        border: 1px solid #ababab;
        top: 35px;
        left: 0px;
    }
}

.buttonContainer {
    // margin-bottom: 15px;
    // margin-top: 15px;
    display: flex;
    // border:1px solid red;
    // background: linear-gradient(135deg, #4BC0C8, #2C3E50);
    // box-shadow: 0px 4px 10px rgba(76, 195, 204, 0.4);
    // border-radius: 3px;

    .customButton {
        font-weight: bold;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        display: flex;

        &.mobilePairing {
            background: linear-gradient(135deg, #4BC0C8, #7c97b3);
            box-shadow: 0px 4px 10px rgba(76, 195, 204, 0.4);
        }

        &:hover {
            transform: scale(1.05);
            box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.3);
        }

        &:active {
            transform: scale(0.8);
        }
    }
}

.qr_code {
    position: absolute;
    top: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s, visibility 0.3s;

    padding: 10px;
    border: 3px solid black;
    border-radius: 5px;
    background-color: white;


    &.show {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.3s, visibility 0.3s;
    }

    &.hide {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s, visibility 0.3s;
    }

    &_item {
        width: 250px !important;
        height: 250px !important;
        // transform: scale(1.5);
        margin-bottom: 10px;
    }

    .copyButton {
        margin-top: 8px;
        padding: 6px 10px;
        font-size: 0.75rem;
        font-weight: bold;
        color: #fff;
        background-color: #4bc0c8;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #3a9da8;
        }
    }

}