@import "../../../assets/scss/common.scss";

.container {
    // border:2px solid red;

    height: calc(100vh - 120px);
    background-color: rgba(221, 195, 139, 0.1);
    display: flex;
    color: white;
    font-size: .75rem;
    // transition: all ease 1s;

    &_first {
        min-width: 320px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        overflow-y: auto;
    }

    &_second {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        &>div {
            height: 100%;
            width: 100%;
            display: flex;
            flex-grow: 1;
        }
    }
}