@import "../assets/scss/common.scss";

@keyframes gradientAnimation {

    0%,
    100% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }
}

$width: 14px;
$height: 23px;

.container {
    display: flex;
    background-color: rgba($color: $font_color, $alpha: .8);
    color: white;
    padding:5px;
    font-family: $amanin_font_family;

    & .number {
        display: flex;
        flex-direction: column;

        &_d {
            text-align: center;
            min-height: 20px;
            font-size: .80rem;
            font-weight: bold;
        }

        &_v {
            display: flex;
            &>span {
                display: inline-flex;
                width: $width;
                height: $height;
                font-size: 1.3rem;
                font-weight: 600;
                margin: 1px;
                padding: 2px;
                background-color: rgb(52, 52, 52);
                justify-content: center;
                align-items: center;

                &.dp {
                    background-color: transparent;
                    border-color: transparent;
                    width: calc($width / 3);
                }
            }
        }
    }
}