@import "../../assets/scss/common.scss";

.container {
    height: 100vh;
    background: linear-gradient(30deg,
            rgb(42, 109, 218),
            rgb(99, 135, 154),
            #2a87a1,
            #92a7de);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .2s;
    font-size: .9rem;
    font-family: $amanin_font_family;


    @include responsiveDesktopStandard() {
        font-size: .9rem;
    }

    @include responsiveDesktopMedium() {
        font-size: .7rem;
    }

    @include responsiveDesktopSmall() {
        font-size: .6rem;
    }

    // @include responsiveMobileStandard() {
    //     font-size: .6rem;
    // }

    // @include responsiveMobileMedium() {
    //     font-size: .6rem;
    // }

    // @include responsiveMobileSmall() {
    //     font-size: .6rem;
    // }

    & .onlinedetected {
        border: 1px solid white;
        z-index: 202;
        background-color: rgba(255, 255, 255, 0.54);
        padding: 20px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;

        &>div {
            margin: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &>img {
                height: 100px;
                margin-bottom: 10px;
                background-color: white;
                padding: 10px;
                border-radius: 5px;
            }

            &>span {
                font-size: .75rem;
                font-weight: bold;
            }
        }
    }

    & .circle {
        & .one {
            background: rgba(255, 255, 255, 0.04);
            /* Warna latar belakang dengan opasitas */
            position: absolute;
            top: 0px;
            width: 100vw;
            height: 100vw;
            border-radius: 100% 0 0 0;
            backdrop-filter: blur(3px);
            right: 00px;
        }

        & .two {
            background: rgba(255, 255, 255, 0.04);
            /* Warna latar belakang dengan opasitas */
            position: absolute;
            width: 100vw;
            height: 100vw;
            border-radius: 0 100% 0 0;
            backdrop-filter: blur(3px);
            left: 00px;
        }
    }

    & .cform {
        display: flex;
        align-items: center;
        height: 100vh;

        @include responsiveMobileMedium() {
            transform: scale(.85);
        }

        &_lang {
            position: fixed;
            z-index: 100;
            left: 0px;
            top: 0;
            margin: 10px;

            @include responsiveMobileSmall() {
                transform: scale(.65);
                // border: 2px solid red;
            }

            @include responsiveMobileMedium() {
                transform: scale(.85);
                // border: 2px solid yellow;
            }

            @include responsiveMobileStandard() {
                transform: scale(.95);
                // border: 2px solid green;
            }
        }

        & .form {
            position: relative;
            margin: 0 auto;
            z-index: 201;
            background-color: white;
            // background-color: red;
            border-radius: 3px;
            box-shadow: 1px 1px 10px rgba(50, 108, 183, 0.5);
            display: flex;
            flex-direction: column;
            font-family: $amanin_font_family;
            transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
            min-width: 500px;


            @include responsiveDesktopStandard() {
                min-width: 500px;
            }

            @include responsiveDesktopMedium() {
                min-width: 400px;
            }

            @include responsiveDesktopSmall() {
                min-width: 300px;
            }

            @include responsiveMobileMedium() {
                min-width: 300px;
            }

            &_top {
                margin-top: 20px;
                text-align: center;

                &>img {
                    height: 150px;
                    margin-top: 5px;

                    @include responsiveDesktopStandard() {
                        height: 150px;
                    }

                    @include responsiveDesktopMedium() {
                        height: 110px;
                    }

                    @include responsiveDesktopSmall() {
                        height: 80px;
                    }

                    @include responsiveMobileStandard() {
                        height: auto;
                        width: 90px;
                    }

                    @include responsiveMobileMedium() {
                        height: auto;
                        width: 70px;
                    }

                    @include responsiveMobileSmall() {
                        height: auto;
                        width: 50px;
                    }
                }

                & .title {
                    color: $font_color;
                    font-weight: bold;
                    align-items: center;
                    text-align: center;

                    font-size: 1.2rem;

                    @include responsiveDesktopStandard() {
                        font-size: 1.2rem;
                    }

                    @include responsiveDesktopMedium() {
                        font-size: 1.0rem;
                    }

                    @include responsiveDesktopSmall() {
                        font-size: .8rem;
                    }

                    @include responsiveMobileMedium() {
                        font-size: .9rem;
                    }
                }

                & .title2 {
                    color: $font_color;
                    font-weight: 500;
                    align-items: center;
                    text-align: center;

                    @include responsiveMobileMedium() {
                        font-size: .7rem;
                    }

                }

                & .title3 {
                    color: $font_color;
                    font-weight: 500;
                    margin-top: 5px;
                    align-items: center;
                    text-align: center;

                    @include responsiveMobileMedium() {
                        font-size: .7rem;
                    }
                }
            }

            &_bottom {
                display: flex;
                margin: 20px;

                @include responsiveDesktopStandard() {
                    margin: 20px;
                }

                @include responsiveDesktopMedium() {
                    margin: 15px;
                }

                @include responsiveDesktopSmall() {
                    margin: 10px;
                }

                @include responsiveMobileMedium() {
                    margin-inline: 10px;
                }


                &_lleft {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    border-right: 1px solid rgba($color: $amanin_logo_color2, $alpha: 0.5);
                    margin-right: 10px;
                    padding-right: 10px;

                    & .info {
                        white-space: wrap;
                        background-color: rgb(164, 98, 98);
                        color: white;
                        padding: 5px;
                        border-radius: 5px;
                        margin: 10px;
                        font-weight: 400;
                        width: 190px;
                        text-align: center;



                        @include responsiveDesktopStandard() {
                            width: 190px;
                        }

                        @include responsiveDesktopMedium() {
                            width: 150px;
                        }

                        @include responsiveDesktopSmall() {
                            width: 120px;
                        }

                        @include responsiveMobileMedium() {
                            width: 120px;
                            font-size: .7rem;
                            margin-right: 10px;

                        }

                    }

                    & .infocd {
                        text-align: center;
                        border: 1px solid $font_color;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        border-radius: 5px 5px 0px 0px;
                        padding: 2px;
                        background-color: rgba($color: $amanin_logo_color3, $alpha: 0.5);

                        &>span {
                            margin-bottom: 5px;
                            margin-top: 2px;
                            font-weight: 600;
                        }
                    }
                }

                &_left {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;

                    &>img {
                        height: 220px;
                    }

                    &>span {
                        font-family: $amanin_font_family;
                        color: $amanin_logo_color2;
                        font-weight: 500;
                        text-align: center;

                        &.brand_version {
                            font-style: italic;
                        }
                    }
                }

                &_right {
                    width: 100%;
                    font-family: $amanin_font_family;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    & .input {
                        display: flex;
                        flex-direction: column;

                        &>form {
                            min-width: 120px;
                            margin-left: 10px;
                            margin-right: 10px;

                            &>div {
                                display: flex;
                                flex-direction: column;
                                margin-top: 10px;
                                margin-bottom: 10px;
                                flex-grow: 1;

                                @include responsiveDesktopStandard() {
                                    margin-top: 10px;
                                    margin-bottom: 10px;
                                }

                                @include responsiveDesktopMedium() {
                                    margin-top: 7px;
                                    margin-bottom: 7px;
                                }

                                @include responsiveDesktopSmall() {
                                    margin-top: 4px;
                                    margin-bottom: 4px;
                                }

                                @include responsiveMobileMedium() {
                                    margin-top: 4px;
                                    margin-bottom: 4px;
                                }



                                &>div {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;

                                    &>span {
                                        padding-top: 5px;
                                        padding-bottom: 5px;
                                        color: $font_color;

                                        @include responsiveMobileMedium() {
                                            font-size: .6rem;
                                        }
                                    }

                                    &>img {
                                        border-radius: 3px;
                                        transition: .3s;
                                        height: 25px;

                                        @include responsiveDesktopStandard() {
                                            height: 25px;
                                        }

                                        @include responsiveDesktopMedium() {
                                            height: 20px;
                                        }

                                        @include responsiveDesktopSmall() {
                                            height: 15px;
                                        }

                                        @include responsiveMobileMedium() {
                                            height: auto;
                                            width: 20px;
                                        }

                                        &:hover {
                                            transition: .3s;
                                            cursor: pointer;
                                            background-color: #2a87a14a;
                                        }
                                    }
                                }

                                &>input[type="text"],
                                &>input[type="password"] {
                                    color: rgb(52, 50, 50);
                                    border-style: solid;
                                    /* Anda dapat mengganti "solid" dengan gaya border lainnya, seperti "dashed" atau "dotted" */
                                    border-width: 2px;
                                    /* Sesuaikan lebar border sesuai kebutuhan Anda */
                                    /* Sesuaikan warna border sesuai kebutuhan Anda */
                                    padding: 12px 10px 12px 10px;
                                    /* Sesuaikan padding sesuai kebutuhan Anda */
                                    outline: none;
                                    /* Menghilangkan outline bawaan pada beberapa browser */

                                    transition: .5s;
                                    background-color: $amanin_bg_color;
                                    border-color: $amanin_bg_color;
                                    border-radius: 5px;
                                    font-size: .9rem;
                                    box-shadow: 1px 1px 5px rgba($color: black, $alpha: 0.5);
                                    min-width: 170px;

                                    @include responsiveDesktopStandard() {
                                        padding: 12px 10px 12px 10px;
                                        font-size: .9rem;
                                        min-width: 170px;
                                    }

                                    @include responsiveDesktopMedium() {
                                        padding: 9px 7px 9px 7px;
                                        font-size: .8rem;
                                        min-width: 150px;
                                    }

                                    @include responsiveDesktopSmall() {
                                        padding: 6px 4px 6px 4px;
                                        font-size: .6rem;
                                        min-width: 130px;
                                    }

                                    @include responsiveMobileMedium() {
                                        padding: 6px 4px 6px 4px;
                                        font-size: .6rem;
                                        min-width: 130px;
                                    }

                                    &:hover {
                                        transition: .5s;
                                        box-shadow: 1px 1px 5px rgba($color: black, $alpha: 1);
                                        /* Sesuaikan warna border hover sesuai kebutuhan Anda */
                                    }
                                }
                            }
                        }

                        & .or {
                            text-align: center;
                            margin-bottom: 10px;
                            color: $font_color;
                        }
                    }

                    & .action {
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 20px !important;

                        @include responsiveDesktopStandard() {
                            margin-top: 20px !important;
                        }

                        @include responsiveDesktopMedium() {
                            margin-top: 17px !important;
                        }

                        @include responsiveDesktopSmall() {
                            margin-top: 13px !important;
                        }

                        & .btn {
                            border-radius: 50px;
                            font-size: .9rem;
                            font-weight: 400;
                            text-align: center;
                            padding: 13px 40px 13px 40px;
                            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);

                            @include responsiveDesktopStandard() {
                                padding: 13px 40px 13px 40px;
                                font-size: .9rem;
                            }

                            @include responsiveDesktopMedium() {
                                padding: 10px 37px 10px 37px;
                                font-size: .7rem;
                            }

                            @include responsiveDesktopSmall() {
                                padding: 7px 34px 7px 34px;
                                font-size: .5rem;
                            }

                            @include responsiveMobileMedium() {
                                padding: 7px 34px 7px 34px;
                                font-size: .5rem;
                            }
                        }
                    }
                }
            }

            &_footer {
                color: $font_color;
                max-width: 300px;
                margin: 0 auto;
                font-weight: bold;
                font-style: italic;

                text-align: center;
                margin-bottom: 10px;
                font-size: .8rem;

                @include responsiveDesktopStandard() {
                    font-size: .8rem;
                }

                @include responsiveDesktopMedium() {
                    font-size: .65rem;
                }

                @include responsiveDesktopSmall() {
                    font-size: .55rem;
                }

                @include responsiveMobileMedium() {
                    font-size: .65rem;
                }
            }
        }
    }
}

.loggingin {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $amanin_font_family;
    padding: 10px;
    color: $font_color;
    border-radius: 3px;
    box-shadow: 1px 1px 10px white;

    &>span {
        padding: 20px;
        font-weight: 500;
    }
}

.datetime {
    position: absolute;
    right: -245px;
    white-space: nowrap;
    background-color: #2a87a1;
    border: 1px solid white;
    color: white;
    padding: 5px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 230px;
    font-size: .95rem;

    @include responsiveDesktopStandard() {
        font-size: .95rem;
        width: 230px;
        right: -245px;
    }

    @include responsiveDesktopMedium() {
        font-size: .75rem;
        width: 180px;
        right: -195px;
    }

    @include responsiveDesktopSmall() {
        font-size: .55rem;
        width: 150px;
        right: -165px;
    }

    @include responsiveMobileStandard() {

        top: -18%;
        right: 0;
        left: 0;
        margin-inline: auto;
    }

    @include responsiveMobileMedium() {
        top: -20%;
        right: 0;
        left: 0;
        margin-inline: auto;

        font-size: .8rem;
    }

    & .title {
        font-weight: 500;
    }

    & .time {
        font-weight: 900;
        padding: 3px;
    }
}

.brand {
    position: absolute;
    bottom: 0px;
    right: 0px;

    &>a {
        position: absolute;
        bottom: 0px;
        right: 0px;
        display: inline-flex;
        padding: 5px;
        border-radius: 5px;
        margin: 5px;
        border: 1px solid rgba(255, 255, 255, 0);
        transition: .5s;

        &:hover {
            transition: .5s;
            border: 1px solid white;
        }

        @include responsiveMobileMedium() {
            position: relative;
            bottom: 60px;
            right: 20px;
        }

        &>img {
            height: 40px;

            @include responsiveDesktopStandard() {
                height: 40px;
            }

            @include responsiveDesktopMedium() {
                height: 30px;
            }

            @include responsiveDesktopSmall() {
                height: 20px;
            }

            @include responsiveMobileMedium() {
                height: 20px;
            }
        }
    }
}

.media {
    font-family: $amanin_font_family;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    overflow-y: auto;
    padding-top: 10px;

    &>div {
        display: flex;

        &>div {
            display: flex;
            flex-direction: column;
            align-items: center;

            &.media_b {
                border: 1px solid black;
            }

            &>video {
                border: 1px solid white;
                background-color: black;
                width: 320px;
                height: 240px;
            }

            &>div {}
        }

        &.back {
            background-color: #2a87a1;
            font-weight: bold;
            padding: 5px;
            border-radius: 5px;
            color: white;
            transition: all ease .5s;

            &:hover {
                background-color: #4dacc6;
                cursor: pointer;

            }
        }

        &.activate {
            background-color: rgb(163, 163, 212);
            padding: 5px;
            margin: 5px;
            border-radius: 5px;
            font-weight: bold;
            transition: all ease .5s;

            &:hover {
                background-color: #92a7de;
                cursor: pointer;
            }
        }

        &.info {
            // border:1px solid red;
            max-width: 480px;
            margin: 10px;
            padding: 10px;
            text-align: center;
            border-radius: 5px;
            background-color: rgb(147, 88, 88);
            color: white;
            font-size: 1.0rem;
        }

        &.petunjuk {
            display: flex;
            flex-direction: column;
            align-items: center;
            border: 1px solid red;
            padding: 5px;
            border-radius: 5px;

            margin: 10px;

            &>span {
                font-weight: bold;
            }
        }
    }
}

.testdevice {
    position: absolute;
    right: -235px;
    top: 90px;
    width: 230px;
    z-index: 202;
    // border: 1px solid white;
    // background-color: rgb(192, 87, 87);
    // color: white;
    // font-weight: bold;
    // display: flex;
    // text-align: center;
    // align-items: center;
    // justify-content: center;
    // border-radius: 5px;
    // padding: 5px;
    // transition: all ease .5s;

    &:hover {
        // cursor: pointer;
        // background-color: rgb(117, 77, 77);
    }

    &_content {
        display: flex;
        // border:1px solid red;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: .75rem;
        color: white;

        &>img {
            margin-right: 5px;
            margin-left: 5px;
        }
    }

    @include responsiveMobileSmall() {
        display: none !important;
    }

    @include responsiveMobileStandard() {
        display: none !important;
    }

    @include responsiveMobileMedium() {
        display: none !important;
    }
}

.iframe {
    width: calc(100% - 20px);
    height: calc(100vh - 20px);
    border: 10px solid red;
}

.version {
    margin: 5px;
    font-size: .5rem;
    font-style: italic;
    color: #2a87a1;
}