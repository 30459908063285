@import "../../assets/scss/common.scss";

.container {
    border:1px solid red;
    width: 100vw;
    height: 100vh;
    &>iframe {
        width: 100%;
        height: 100%;
    }
}