@import "../../assets/scss/common.scss";

.container {
    font-family: $amanin_font_family;
    font-size: 0.95rem;
    display: flex;
    height: 1500px;
    background-color: rgba($color: $amanin_logo_color, $alpha: 0.5);
    overflow: auto;
    min-width: 400px;

    &_validation {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        &>div {
            display: flex;
            justify-content: center;
            flex-direction: row;

            &.info {
                display: flex;
                flex-direction: column;
                padding: 10px;
                background-color: white;
                border: 1px solid #ababab;
                border-radius: 10px;
                margin: 20px;

                &>span {
                    padding: 3px;
                }
            }

            &.image {
                display: flex;
                flex-direction: row;

                &>div {
                    background-color: rgba(255, 255, 255, 0.559);
                    border: 1px solid white;
                    border-radius: 5px;
                    padding: 10px;
                    margin: 5px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    & .canvas {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-width: 100px;
                    }
                }

                @media only screen and (max-width: 500px) {

                    & {
                        margin-top: -30px;
                        flex-direction: column !important;
                        transform: scale(0.8);
                    }

                    img {
                        width: max-content;
                    }
                }
            }

            @media only screen and (max-width: 768px) {
                .image_mobile {
                    display: flex !important;
                    flex-direction: row !important;
                    gap: 5px;
                    text-align: center;

                    &_right {
                        display: none;
                    }
                }
            }

            @media only screen and (min-width: 769px) {
                .image_mobile {

                    &_right {
                        display: none;
                    }
                }
            }


            @media only screen and (max-width: 500px) {
                .image_mobile {
                    &_right {
                        display: block;
                    }

                    &_bottom {
                        display: none !important;
                    }
                }
            }

        }
    }

    &_conmsg {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        flex-direction: column;

        &_progress {
            &>div {
                margin: 10px;
                padding: 10px;
                border-radius: 5px;
                background-color: rgba(82, 101, 116, 0.8);
            }
        }

        &_content {
            display: inline-flex;
            height: 50px;
            background-color: rgba(255, 255, 255, 0.8);
            justify-content: center;
            padding: 10px;
            align-items: center;
            font-weight: bold;
            border-radius: 5px;
        }
    }

    &_left {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        height: 100vh;

        &_top {
            display: flex;
            font-size: 1.5rem;
            font-weight: bold;
            align-items: center;
            width: 100%;
            text-align: center;
            height: 40px !important;

            @include responsiveMobileStandard() {
                justify-content: space-between;
                width: 90%;

                .title {
                    display: flex;
                    flex-direction: column;

                    .big {
                        font-size: .85rem;
                    }

                    .small {

                        font-size: .75rem;
                    }
                }
            }

            @include responsiveMobileMedium() {
                justify-content: space-between;
                width: 90%;

                .title {
                    display: flex;
                    flex-direction: column;

                    .big {
                        font-size: .65rem;
                    }

                    .small {

                        font-size: .55rem;
                    }
                }
            }

            &>div {
                display: flex;
                align-items: center;
                // border: 2px solid red;

                &.images {
                    position: absolute;
                }

                // @include responsiveMobileStandard() {
                //     display: flex;
                //     align-items: center;
                //     font-size: .85rem;
                // }

                &.title {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    &>span {
                        &.big {
                            font-size: 1.0rem;
                        }

                        &.small {
                            font-size: 0.8rem !important;
                            font-weight: bold;
                        }
                    }

                    @include responsiveMobileStandard() {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        border: 3px solid green;

                    }
                }

                &>img {
                    margin-left: 5px;
                    background-color: rgba(255, 255, 255, 1);
                    padding: 5px;
                    border-radius: 5px;

                    &.client {
                        padding: 2px !important;
                    }
                }

            }

            @media only screen and (max-width: 500px) {
                &>div {

                    &>div.title {
                        display: flex;
                        flex-direction: column;

                        &>span {
                            &.big {
                                font-size: 1.0rem;
                            }

                            &.small {
                                font-size: 0.8rem !important;
                                font-weight: bold;
                            }
                        }
                    }

                    &>img {
                        width: 26px;

                        &.client {
                            padding: 2px !important;
                        }
                    }

                }
            }

        }

        &_bottom {
            background-color: white;
            height: 100%;
            margin: 0px 5px 5px 5px;
            border-radius: 5px;
            border: 1px solid rgb(238, 238, 238);
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            overflow-y: auto;

            &_video {
                // border:2px solid red;
                position: absolute;
                z-index: 5;
                top: 0px;
                right: 35px;
                margin-right: 15px;
                margin-bottom: 15px;
                // width: 30px;
                height: 40px;

            }

            @media only screen and (max-width: 768px) {
                iframe {
                    position: relative;
                }
            }


            & .media {
                display: flex;
                justify-content: center;

                &_b {
                    border: 1px solid #ababab;
                    margin: 5px;

                    &>video {
                        background-color: #272626;
                        width: 250px;
                        height: 150px;
                    }

                    &>div {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 0.7rem;
                        font-weight: bold;
                    }

                }
            }
        }
    }

    &_right {
        position: relative;
        margin-right: 5px;
    }

    & .container_prepare {
        height: calc(100vh - 45px);
        width: 100%;
        display: flex;
        overflow: auto;
        align-items: flex-start;

        @include responsiveMobileMedium() {
            transform: scale(.9);
        }

        @include responsiveMobileSmall() {
            transform: scale(.8);
        }

        & .prepare {
            flex-grow: 1;
            height: calc(100% - 0px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            overflow-y: auto;

            &_profile {
                display: flex;
                border: 1px solid #ababab;
                background-color: #e3e8ee;
                margin-bottom: 5px;
                border-radius: 5px;
                font-size: .8rem;

                &>div {
                    display: flex;
                    flex-direction: column;
                    padding: 5px;
                }

                &_img {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &>img {
                        max-width: 320px;
                    }
                }
            }

            &_top {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                &_info {
                    max-width: 500px;
                    border: 1px solid black;
                    border-radius: 5px;
                    background-color: rgba(0, 0, 0, 0.8);
                    padding: 5px;
                    color: white;
                    display: flex;
                    align-items: center;




                    & .logo {
                        display: flex;
                        width: 60px;
                        height: 30px;
                        border-radius: 5px;
                        background-color: white;
                        color: black;
                        font-size: 1.8rem;
                        justify-content: center;
                        align-items: center;
                        font-weight: bold;
                        margin: 10px 10px 10px 5px;
                    }

                    & .info {
                        text-align: center;
                        font-size: .85rem;
                    }
                }

                &>button {
                    margin: 5px;
                }
            }

            .preparation_countDown {
                margin-top: 5px;
                padding: 5px;
                background-color: $amanin_logo_color;
                border-radius: 5px;
                width: 90%;
                max-width: 170px;
                display: flex;
                flex-direction: column;
                text-align: center;
                margin-bottom: 0px;

                &_text,
                span {
                    font-size: .9em;
                    font-weight: bold;
                    margin-bottom: 5px;
                    background-color: rgb(48, 60, 87);
                    color: white;
                    padding: 10px 0;
                    border-radius: 5px;
                }

                &_timer {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    font-size: .9rem;
                    border-radius: 5px;
                    font-weight: bold;
                    background-color: white;
                    height: 40px;
                    padding-top: 3px;
                    padding-bottom: 3px;

                    &>h6 {
                        font-size: .75rem;
                    }

                    & .time {
                        display: flex;
                        justify-content: center;
                        width: 90%;

                        div {
                            width: 100%;
                            position: relative;
                            gap: 100px;

                            &:nth-child(1) {
                                left: 2px;
                            }

                            &:nth-child(2) {
                                right: 4px;
                            }

                            &:nth-child(3),
                            &:nth-child(4) {
                                right: 8px;
                            }

                            &:nth-child(2)::after {
                                content: ':';
                                position: absolute;
                                right: 1px;
                            }

                            &:nth-child(3)::after {
                                content: ':';
                                position: absolute;
                                right: -3px;
                            }
                        }
                    }

                    & .info {
                        display: flex;
                        font-size: .8rem;
                        column-gap: 7px;
                    }
                }
            }

        }
    }

    @media only screen and (max-width: 768px) {
        height: max-content;

        // .btnMobile {
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     flex-direction: column;
        //     background-color: rgba(255, 255, 255, 0.8);
        //     height: 50px;
        //     border:2px solid red;

        //     .btnHelp,
        //     .btnReload {
        //         img {
        //             width: 20px;
        //         }
        //     }
        // }
    }
}

.control {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    transition: width .5s;
    z-index: 100;

    &_toggle {
        position: absolute;
        display: flex;
        justify-content: center;
        font-weight: bold;
        align-items: center;
        width: 28px;
        height: 28px;
        margin: 4px;
        background-color: white;
        left: -40px;
        border-radius: 5px 0px 0px 5px;
        border: 1px solid white;

        &>img {
            display: inline-flex;
            border-radius: 5px;
            transition: transform .5s;
        }

        &:hover {
            cursor: pointer;
            border: 1px solid #5b5b5b;
        }

    }

    &_timer {
        display: flex;
        height: 30px;
        background-color: white;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        margin-top: 4px;
        margin-bottom: 5px;
    }

    &_examTime {
        display: flex;
        background-color: white;
        align-items: center;
        justify-content: center;
        font-size: .8rem;
        border-radius: 5px;
        color: rgb(41, 41, 68);
        font-weight: bold;
        margin-bottom: 5px;
        padding: 5px;
    }

    &_profile {
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 5px;
        padding-bottom: 5px;
        font-size: 0.7rem !important;
    }

    &_webcam {
        margin-top: 5px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 5px;
        width: 280px;
        // height: 220px;
        // height: 150px;
        padding-top: 5px;
        padding-bottom: 5px;

        & .media {
            display: flex;
            justify-content: center;

            &_facescreen {
                // border: 1px solid #ababab;
                // margin: 5px;

                &>span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 0.7rem;
                    font-weight: bold;
                }

            }
        }

    }

    &_webcamsmall {
        flex-wrap: wrap;
        width: 70px;
        // height: 280px !important;
        padding: 0px;
        justify-content: center;
        align-items: center;

        border: 0px solid red;

        span {
            font-size: 0.5rem !important;
        }

        // margin-bottom: 1.25em;
    }

    &_info {
        display: flex;
        flex-direction: row;
        margin-top: 5px;
        background-color: rgba(255, 255, 255, 0.5);
        padding: 5px;
        border-radius: 5px;

        & .c {
            display: flex;
            background-color: white;
            flex-grow: 1;
            justify-content: flex-start;
            align-items: center;

            &>div {
                background-color: rgb(42, 92, 46);
                color: white;
                padding: 5px 7px 5px 7px;
                border-radius: 5px;
                font-weight: bold;
                font-size: .9rem;
                margin-right: 5px;
                margin-left: 5px;
                margin-top: 5px;
                margin-bottom: 5px;

                &.warning {
                    background-color: rgb(123, 10, 10) !important;
                }
            }

            &>span {
                font-size: 0.8rem;
            }
        }
    }

    &_chat {
        margin-top: 5px;
    }

    &_indicator {
        display: flex;
        justify-content: end;
        margin-top: 20px;
    }
}

.container_broadcastProc {
    font-size: .75rem;
    border: 2px solid #303c57;
    border-radius: 5px;
    margin: 0 auto 5px auto;

    &_tittle {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #c83030;
        padding: 2px;
        color: white;
        font-weight: bold;
        border-radius: 3px 3px 0 0;
    }

    &_message {
        background-color: white;
        padding: 5px;
        border-radius: 0 0 5px 5px;
        overflow-wrap: break-word;
        max-height: 150px;
        overflow-y: auto;
    }
}

.chat_mobile {
    display: flex;
    position: fixed;
    right: 3px;
    top: 3px;
    z-index: 3;
}

.chat_status {
    display: flex;
    position: fixed;
    bottom: 30px;
    right: 30px;

    &_private {
        background-color: white;
        border: 1px solid #ababab;
        border-radius: 5px;
        padding: 5px;
        transition: transform .5s;

        &_header {
            display: flex;
            position: relative;
            justify-content: flex-end;

            button {
                cursor: pointer;
                background: none;
                border: none;

                img {
                    width: 20px;
                }

                i {
                    svg {
                        color: $amanin_cool_color !important;
                        width: 30px;
                        height: 30px;
                        vertical-align: middle;
                        overflow: hidden;
                        fill: currentColor;

                    }
                }
            }

            &_new {
                position: absolute;
                // background-color: rgb(167, 3, 3);
                background-color: white;
                font-weight: bold;
                border-radius: 3px 3px 0px 3px;
                right: -6px;
                top: -35px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid red;

                & .icon {
                    // border:2px solid white;
                    height: 30px !important;
                    width: 30px !important;
                    background-color: rgb(186, 0, 0);
                }

                &>span {
                    display: flex;
                    white-space: nowrap;
                    padding-left: 5px;
                    padding-right: 5px;
                    font-size: .75rem;
                    // border:1px solid red;
                }
            }
        }
    }

    &_button {
        display: flex;
        align-items: flex-end;

        button {
            background-color: $amanin_logo_color;
            color: white;
            padding: 25px 50px;
            border-radius: 7px;
            font-size: 1rem;
            font-weight: 500;
            bottom: 0;
        }
    }
}

.manual {
    height: calc(100% - 10px);
    max-width: 500px;
    min-width: 500px;
    background-color: rgba(212, 192, 192, 0.5);
    padding: 5px;
    flex-direction: column;
    font-size: .75rem;
    align-items: center;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-right: 5px;

    &_img {
        display: flex;
        max-width: 400px;
        border: 1px solid #ababab;
        border-radius: 5px;
        background-color: rgb(172, 203, 207);
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin: 5px;
        padding: 3px;
    }

    &>span {
        justify-content: center;
        width: 100%;
        text-align: center;
        margin-bottom: 5px;
        font-weight: bold;
    }

    &>div {
        border: 1px solid rgb(255, 255, 255);
        border-radius: 5px;
        width: calc(100% - 10px);
        background-color: rgba(255, 255, 255, 0.844);
        padding: 5px;

        &>ol {
            font-size: .75rem;
            margin: 0px;
            padding-left: 15px;

            &>li {
                margin-top: 8px;

                &>ul {
                    padding-left: 15px;

                    &>li {
                        padding-left: 5px;
                    }
                }
            }
        }
    }
}

.manonexam {
    display: flex;
    border: 1px solid black;
    flex-direction: column;
    margin-top: 5px;
    background-color: rgba(212, 192, 192, 0.5);
    font-size: .75rem;
    align-items: center;
    border-radius: 5px;

    &>span {
        font-weight: bold;
        padding: 5px;
    }

    &>div {
        margin-top: 2px;
        background-color: white;
        border-radius: 0 0 5px 5px;

        &>ol {
            font-size: .75rem;
            margin-left: 5px;
            padding-left: 15px;

            &>li {
                margin-top: 8px;

                &>ul {
                    padding-left: 15px;

                    &>li {
                        padding-left: 5px;
                    }
                }
            }
        }
    }
}

.help_alert {
    border: 1px solid black;
    font-size: .75rem;
    font-style: italic;
    max-width: 500px;
    text-align: center;
    border-radius: 5px;
    background-color: #303c57;
    color: white;
    padding: 5px;
}

.linkquiz {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
}

.troubleshooting {
    margin-left: 10px;
    display: flex;
    position: relative;
    font-size: .75rem;
    color: white;
    transition: .2s;

    &_title {
        display: flex;
        justify-content: center;
        background-color: rgb(23, 47, 72);
        align-items: center;
        justify-content: center;
        padding-left: 3px;
        padding-right: 3px;
        border-radius: 5px;
        transition: .2s;

        &>img {
            margin-right: 3px;
            padding-top: 2px;
            height: 35px;
        }


        &:hover {
            transition: .2s;
            cursor: pointer;
            background-color: rgba(23, 47, 72, 0.5);
        }
    }

    &_content {
        min-width: 250px;
        max-width: 200px;
        max-height: 500px;
        position: absolute;
        top: 39px;
        background-color: #272626;
        font-weight: normal;
        left: -50px;
        border-radius: 5px;
        justify-content: flex-start;

        &>div {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            background-color: rgb(40, 126, 110);
            padding: 10px;
            margin: 3px;
            transition: .2s;

            &>span {
                text-align: left;
            }

            &:hover {
                transition: .2s;
                cursor: pointer;
                color: black;
                background-color: rgb(133, 185, 109);
            }
        }

        & .img {
            z-index: 2001;
            position: absolute;
            background-color: rgb(40, 126, 110);
            left: 250px;
            top: -39px;
            min-width: 400px;
            overflow-y: auto;
            border: 2px solid rgb(40, 126, 110);
            max-width: calc(100vw - 350px);
            height: calc(100vh - 50px);
        }
    }
}

.btnMobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    .btnHelp,
    .btnReload {
        img {
            width: 20px;
        }
    }
}

.facereference {
    border: 10px solid red;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3000;
}
