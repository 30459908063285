@import "../../assets/scss/fonts.scss";

@import "./fonts.scss";

@mixin responsive($min-width, $max-width: null) {
    @if $max-width {
        @media screen and (min-width: $min-width) and (max-width: $max-width) {
            @content;
        }
    }

    @else {
        @media screen and (min-width: $min-width) {
            @content;
        }
    }
}


@mixin responsiveDesktopStandard() {
    @include responsive(1277px) {
        @content;
    }
}

@mixin responsiveDesktopMedium() {
    @include responsive(1022px, 1277px) {
        @content;
    }
}

@mixin responsiveDesktopSmall() {
    @include responsive(767px, 1022px) {
        @content;
    }
}

// diff = 255
@mixin responsiveMobile() {
    @include responsive(0px, 767px) {
        @content;
    }
}

@mixin responsiveMobileStandard() {
    @include responsive(512px, 767px) {
        @content;
    }
}

@mixin responsiveMobileMedium() {
    @include responsive(256px, 512px) {
        @content;
    }
}

@mixin responsiveMobileSmall() {
    @include responsive(0px, 256px) {
        @content;
    }
}

$amanin_font_family: 'OpenSans';
$amanin_font_size: 0.75rem;
$amanin_cool_color: white;
$amanin_cool_opacity: 0.7;

$amanin_bg_color: rgb(148, 201, 228);
$font_color: rgb(84, 114, 142);

$amanin_header_color: #264750;
$amanin_logo_color: #82c9ec;
$amanin_logo_color2: rgb(0, 159, 239);
$amanin_logo_color3: rgb(152, 211, 241);

$btn_blue_color: rgb(42, 109, 218);

$validation_green: green;
$validation_yellow: yellow;
$validation_orange: orange;
$validation_pink: pink;
$validation_red: red;

$bg_color_dark: rgb(37, 37, 37);

.LoaderContainer {
    width: 100%;
    height: 100%;
}

.btnOk {
    background-color: $btn_blue_color;
    border: 2px solid $btn_blue_color;
    padding: 8px 15px 8px 15px;
    color: white;
    font-weight: bold;
    font-size: .75rem;
    border-radius: 5px;
    transition: all ease .5s;

    &:hover {
        transition: .5s;
        cursor: pointer;
        background-color: rgba(42, 109, 218, 0.8);
    }
}

.btnCancel {
    border: 2px solid $btn_blue_color;
    padding: 8px 15px 8px 15px;
    color: rgb(59, 56, 56);
    font-weight: bold;
    font-size: .75rem;
    border-radius: 5px;
    transition: all ease .5s;

    &:hover {
        cursor: pointer;
        background-color: rgba(42, 109, 218, 0.2);
    }
}

.btnHelp {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(179, 51, 31);
    border: 2px solid rgb(179, 51, 31);
    padding: 5px;
    font-size: .8rem;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    transition: all ease .5s;

    &>img {
        background-color: white;
        border-radius: 50px;
    }

    &>span {
        margin-left: 5px;
    }

    &:hover {
        background-color: rgba(179, 51, 31, 0.8);
        cursor: pointer;
    }
}

.btnReload {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(25, 121, 108);
    border: 2px solid rgb(25, 121, 108);
    padding: 5px;
    font-size: .8rem;
    border-radius: 5px;
    color: white;
    font-weight: bold;

    &>img {
        background-color: white;
        border-radius: 50px;
    }

    &>span {
        margin-left: 5px;
    }

    &:hover {
        background-color: rgba(25, 121, 108, 0.8);
        cursor: pointer;
    }
}

.btnLink {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(239, 212, 181);
    border: 2px solid rgb(214, 183, 148);
    padding: 10px;
    font-size: .9rem;
    color: rgb(54, 46, 46);
    font-weight: bold;

    &>img {
        background-color: white;
        border-radius: 50px;
    }

    &>span {
        margin-left: 5px;
    }

    &:hover {
        background-color: rgba(239, 212, 181, 0.8);
        cursor: pointer;
    }
}

.col {
    display: flex;
    align-items: center;

    &_add {
        z-index: 0;

        &:hover {
            cursor: pointer;
        }
    }

    &_mode {
        display: inline-flex;
        margin-left: 2px;
        padding: 5px;
        border-radius: 5px;
        align-items: center;

        &:hover {
            cursor: pointer;

            & .close {
                display: inline-block;
            }
        }

        &_watch {
            background-color: #adcdd6;
        }

        &_recording {
            background-color: #b5e7b4;
        }

        &_face_recognition {
            background-color: #bba2d1;
        }

        &_motion_detection {
            background-color: #e9bed2;
        }

        &_queue_detection {
            background-color: #bbd1a2;
        }

        &_object_detection {
            background-color: #c7c2c9;
        }

        &_inactive {
            background-color: #264750;
            display: inline-block;
            margin-left: 2px;
            padding: 5px;
            border-radius: 5px;

            &_content {
                display: inline-flex;
                align-items: center;
                margin-top: 2px;
                margin-bottom: 2px;

                &:hover {
                    cursor: pointer;
                    & .close {
                        display: inline-block;
                    }
                }

                &>span {
                    color: rgb(226, 226, 226);
                    font-weight: bold;
                }
            }
        }
    }
}

.close {
    display: none;
    background-color: white;
    border-radius: 10px;
    margin-left: 2px;
}

@keyframes FadeInAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes FadeOutAnimation {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.FadeInPage {
    animation: FadeInAnimation 0.5s;
}

.FadeOutPage {
    animation: FadeOutAnimation 0.5s;
}

.FadeInPage1s {
    animation: FadeInAnimation 1s;
}

.FadeOutPage1s {
    animation: FadeOutAnimation 1s;
}

.scrollbar {
    scrollbar-color: red yellow;
}

// scaleY animation
@keyframes scaleYShowAnimation {
    0% {
        transform: scaleY(0.5);
        opacity: 0;
    }

    50% {
        transform: scaleY(1.1);
        opacity: .5;
    }

    100% {
        transform: scaleY(1.0);
        opacity: 1;
    }
}

@keyframes scaleYHideAnimation {
    0% {
        transform: scaleY(1);
    }

    100% {
        transform: scaleY(0);
    }
}

.ScaleYShow1s {
    animation: scaleYShowAnimation 1s; // ease-in-out;// infinite alternate;
}

.ScaleYHide1s {
    animation: scaleYHideAnimation 1s ease-in-out; // infinite alternate;
}

// end of scaleY animation

//phone
$phone_width: 400px;
$phone_width_sm: 200px;

// Mobile View Handler
$tablet_width: 900px;
$tablet_height: 800px;
$tablet_scale: 0.7;

// Small
$tablet_width_sm: 800px;
$tablet_height_sm: 400px;
$tablet_scale_sm: 0.5;

::-webkit-scrollbar {
    width: 12px;
}


/* Track */

/* Handle */

::-webkit-scrollbar-thumb {
    background: rgba(100, 100, 100, 1.0);
}


.ascroll::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}


/* Track */
.ascroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 3px;
    border-radius: 3px;
}


/* Handle */

.ascroll::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 3px;
    background: #777fae;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.button2div {
    outline: none;
    background: none;
    border: none;
    text-decoration: none;
}