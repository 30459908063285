@import "../../assets/scss/common.scss";

.container {
    // border: 2px solid red;
    position: relative;
    font-family: $amanin_font_family;
    font-size: 1.0rem;

    &_content {
        display: flex;
        flex-direction: column;
        // width: 500px;
        // align-items: center;
        position: absolute;
        background-color: black;
        color: white;
        border-radius: 10px;
        right: 0px;
        top: -15px;
        border: 1px solid rgba(255, 255, 255, 0.5);
        // border:2px solid red;
        box-shadow: 0px 4px 6px rgba(255, 255, 255, 0.5);

        &>div {
            display: flex;
            align-items: center;

            & .time {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 5px;
                background-color: rgba(255, 255, 255, 0.3);
                margin-right: 10px;
                border-radius: 10px 0 0 0px;

                &>img {
                    margin: 3px;
                    width: 25px;
                    height: 25px;
                    background-color: green;
                    padding: 5px;
                    border-radius: 50px;
                    margin-right: 5px;
                }

                &>span {
                    white-space: nowrap;
                    font-size: 1.5rem;
                }
            }

            &>span {
                white-space: nowrap;
                margin-right: 10px;
            }

            &.bottom {
                padding: 5px;
                align-items: center;
                justify-content: center;
            }
        }
    }
}