@import "../../../assets/scss/common.scss";

.image {
    display: flex;

    // border:1px solid red;
    &>img {
        height: 80px;
        padding: 10px;
    }
}

.profile {
    // border: 3px solid blue;
    display: flex;
    flex-direction: row;
    height: 100%;
    flex-grow: 1;

    &_user {
        display: flex;
        flex-direction: column;
        // border:1px solid red;
        justify-content: space-around;
        // padding: 5px;

        &_id {
            // border: 1px solid red;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            &>span {
                font-weight: bold;
                color: white;
            }

            &_validity {
                display: flex;
                // border:1px solid red;
                align-items: center;

                & .valid {
                    color: green;
                    font-size: .6rem;
                    font-weight: bold;
                    background-color: white;
                    padding: 0px 5px 0px 5px;
                    margin-left: 5px;
                    border-radius: 3px;
                }

                & .invalid {
                    color: white;
                    font-size: .6rem;
                    font-weight: bold;
                    background-color: red;
                    padding: 0px 5px 0px 5px;
                    margin-left: 5px;
                    border-radius: 3px;
                }

                & .blocked {
                    color: red;
                    font-size: .6rem;
                    font-weight: bold;
                    background-color: white;
                    padding: 0px 5px 0px 5px;
                    margin-left: 5px;
                    border-radius: 3px;
                }
            }
        }

        &>span {
            margin: 2px;
            font-size: .8rem;
            color: white;

            &.id {
                font-weight: bold;
            }
        }

        &_info {
            display: flex;

            // border:1px solid red;
            &>img {
                width: 20px;
                height: 20px;
                padding: 5px;
                margin-right: 6px;
                // border:1px solid red;
                background-color: rgb(224, 229, 204);
                border-radius: 5px;
            }

            &>div {
                font-size: .7rem;
                color: white;
                display: flex;
                align-items: center;
                padding: 3px;
                margin-right: 6px;
                border-radius: 5px;

                &.online {
                    background-color: rgb(0, 84, 0);
                    border: 1px solid green;
                }

                &.offline {
                    background-color: rgb(84, 0, 15);
                    border: 1px solid rgb(128, 0, 34);
                }

                &.call {
                    // border:1px solid red;
                    background-color: green;
                    border-radius: 50px;
                    padding: 5px;
                    transition: all .5s;

                    &>img {
                        width: 20px;
                    }

                    &:hover {
                        cursor: pointer;
                        background-color: rgb(112, 188, 110);
                    }
                }

                &.hangup {
                    // border:1px solid red;
                    background-color: red;
                    border-radius: 50px;
                    padding: 1px;
                    transition: all .5s;
                    width: 28px;
                    height: 28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &>img {
                        width: 100%;
                        // width: 25px;
                        // height: 20px;
                    }

                    &:hover {
                        cursor: pointer;
                        background-color: rgb(188, 119, 110);
                    }
                }
            }
        }
    }

    &_action {
        display: flex;
        margin-left: 10px;
        // border:1px solid white;
        justify-content: center;
        align-items: center;

        &>img {
            width: 30px;
            padding: 5px;
            // border:1px solid red;
            background-color: rgb(224, 229, 204);
            border-radius: 10px;
        }
    }
}

.action {
    // border: 3px solid green;
    margin-right: 10px;
    display: flex;

    &>img {
        border: 3px solid rgb(207, 229, 204);
        background-color: rgb(207, 229, 204);
        width: 35px;
        padding: 5px;
        border-radius: 10px;
        margin: 3px;
        transition: .5s;

        &:hover {
            border: 3px solid rgb(146, 63, 214);
            cursor: pointer;
        }

        &.chosen {
            background-color: rgb(85, 195, 228);
            border: 3px solid transparent;

        }
    }
}

.flagAttention {
    margin-inline: auto;
}