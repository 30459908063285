@import "../../assets/scss/common.scss";

.container {
    height: 100vh;
    // background-color: red;
    // background-color: $amanin_logo_color;
    display: flex;
    // justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    font-family: $amanin_font_family !important;
    font-size: $amanin_font_size !important;

    // &_c {
    //     opacity: 0;
    //     margin-top: 10px;
    // }

    &_t {
        // position: fixed;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        width: 100%;

        &_t {
            display: flex;
            flex-direction: column;
            align-items: center;

            & .header {
                font-size: 1.3rem;
                font-weight: bold;
            }

            & .sub {
                font-size: .9rem;
                font-weight: bold;
                padding: 3px;
            }

            &.sub2 {
                font-size: 0.9rem;
                padding: 3px;
            }
        }

        & .rules {
            display: flex;
            background-color: rgba(255, 255, 255, 0.5);
            padding: 5px;
            border-radius: 5px;

            &>div {
                background-color: white;
                border: 2px solid white;
                margin: 1px;
                padding: 5px;
                font-weight: bold;
                font-size: .7rem;
                border-radius: 5px 5px 5px 5px;

                &.chosen {
                    background-color: #674b5f !important;
                    color: white;
                }

                &:hover {
                    cursor: pointer;
                    background-color: #cfdfce;
                }
            }
        }

        &_d {
            // border: 2px solid red;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            align-items: center;

            & .divider {
                display: flex;
                flex-direction: column;
                // margin-top: 10px;
                // border:1px solid red;
                width: 100%;
                height: calc(100vh - 600px);
                align-items: center;
                justify-content: flex-start;

            }

            & .preparation_countDown {
                margin-top: 10px;
                padding: 10px;
                background-color: rgba(255, 255, 255, 0.5);
                border-radius: 5px;
                width: 100%;
                max-width: 280px;
                display: flex;
                flex-direction: column;
                text-align: center;

                &_text,
                span {
                    font-size: .8em;
                    font-weight: bold;
                    margin-bottom: 5px;
                    background-color: rgb(48, 60, 87);
                    color: white;
                    padding: 10px 0;
                    border-radius: 5px;
                }

                &_timer {
                    // border: 5px solid red;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    font-size: 1.3rem;
                    border-radius: 5px;
                    font-weight: bold;
                    background-color: white;
                    height: 100px;


                    & .time {
                        display: flex;
                        // column-gap: 30px;
                        justify-content: center;
                        width: 65%;

                        div {
                            width: 100%;
                            position: relative;
                            margin-right: auto;

                            &:nth-child(1) {
                                left: 2px;
                                font-size: 1.4rem;
                                top: -2px;
                                // color: $amanin_logo_color;
                                -webkit-text-fill-color: $amanin_logo_color2 ;

                            }

                            &:nth-child(2),
                            &:nth-child(3),
                            &:nth-child(4) {
                                right: 5px;
                            }

                            &:nth-child(2)::after,
                            &:nth-child(3)::after {
                                content: ':';
                                position: absolute;
                                right: -3px;
                            }

                            &:nth-child(3)::after {
                                animation: animate 1s steps(1) infinite;
                            }

                            // #days {
                            //     border: 1px solid red;
                            //     left: 2px;
                            //     font-size: 1.4rem;
                            //     top: -2px;
                            //     // color: $amanin_logo_color;
                            //     -webkit-text-fill-color: $amanin_logo_color2 ;

                            //     color: red;

                            // }

                            // #hours,
                            // #minutes,
                            // #second {
                            //     right: 5px;
                            // }

                            // #hours::after,
                            // #minutes::after {
                            //     content: ':';
                            //     position: absolute;
                            //     right: -3px;
                            // }

                            // #minutes::after {
                            //     animation: animate 1s steps(1) infinite;
                            // }
                        }
                    }

                    & .info {
                        display: flex;
                        font-size: .8rem;
                        column-gap: 15px;

                        div {

                            &:nth-child(1) {
                                // color: $amanin_logo_color;
                                -webkit-text-fill-color: $amanin_logo_color2 ;
                                // -webkit-text-stroke: 1.5px $amanin_logo_color2;
                            }

                            // #hours {
                            //     -webkit-text-fill-color: $amanin_logo_color2 ;
                            // }

                        }
                    }
                }
            }

            & .proctoring_management_text {
                margin-top: 20px;

                & a {
                    text-decoration: none;
                    font-size: .8rem;
                    font-weight: 500;
                    color: black;

                    &:hover {
                        color: white;

                    }
                }
            }

            &_d {
                display: flex;
                flex-direction: column;
                // border:2px solid red;
                max-width: 470px;
                // background-color: rgba(255, 255, 255, 0.5);
                padding: 5px;
                border-radius: 5px;
                font-size: .7rem;
                // align-items: center;
                justify-content: center;

                & .detail {
                    display: flex;
                    align-items: center;
                    // border: 1px solid red;
                    margin: 2px;
                    background-color: rgba(255, 255, 255, 0.5);
                    padding: 7px;
                    border-radius: 5px;


                    &_time {
                        background-color: rgb(48, 60, 87);
                        color: white;
                        padding: 3px;
                        border-radius: 5px;
                        font-weight: bold;
                        margin-right: 5px;
                        text-align: center;
                        padding-inline: 7px;
                    }

                    &_text {
                        display: flex;
                        flex-direction: column;
                        background-color: white;
                        flex-grow: 1;
                        padding: 5px;
                        border-radius: 5px;

                        &>span {
                            max-width: 350px;
                        }
                    }

                    &_top {
                        font-size: .80rem;
                        display: flex;
                        flex-direction: column;
                        // background-color: rgba(227, 184, 145, 0.25);
                        flex-grow: 1;
                        padding: 5px;
                        border-radius: 5px;
                        font-weight: bold;
                        text-align: center;

                        &>span {
                            // max-width: 350px;
                            max-width: 350px;
                        }
                    }

                    &_bottom {
                        font-size: .80rem;
                        display: flex;
                        align-items: center;
                        justify-content: space-evenly;
                        padding: 5px;
                        text-align: left;
                    }
                }
            }
        }
    }



    &_card {
        padding: 7px;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &_t {
            display: flex;
            flex-direction: column;
            width: calc(100% - 100px);
            background-color: white;
            justify-content: center;
            align-items: center;
            padding: 15px 50px 10px 50px;
            border-radius: 5px 5px 0px 0px;
            margin-bottom: 4px;

            &>span {
                display: flex;
                align-items: center;
                justify-content: center;
                // border:1px solid black;
                font-weight: bold;
                font-size: 1.0rem;
                color: $amanin_logo_color2;
                margin-top: 5px;
            }
        }

        &_b {
            // border:10px solid green;
            width: 100%;
            display: flex;

            &>span {
                &>img {
                    margin-right: 10px;
                }

                display: inline-flex;
                align-items: center;
                width:140px;
                padding:5px;
                background-color: white;
                font-weight: bold;
                font-size: .7rem;

                &.l {
                    margin-right: 2px;
                    border-radius: 0px 0px 0px 5px;
                    border: 2px solid white;
                }

                &.r {
                    margin-left: 2px;
                    border-radius: 0px 0px 5px 0px;
                    border: 2px solid white;
                }

                &:hover {
                    background-color: #cfdfce;
                    cursor: pointer;
                }
            }

            & .form {
                // border: 2px solid red;
                background-color: white;
                width: 100% !important;
                display: flex;
                flex-direction: column;
                font-size: .7rem;
                border-radius: 0px 0px 5px 5px;
                // padding:0px 5px 0px 0px;

                &_title {
                    display: flex;
                    justify-content: center;
                    font-weight: bold;
                    padding: 5px;
                    font-size: .7rem;
                    align-items: center;
                    text-align: center;
                    // border: 1px solid black;
                    width: 80%;
                    margin-inline: auto;
                }

                &_f {
                    display: flex;
                    flex-direction: row;
                    // border:1px solid red;
                    min-width: 320px;

                    &_p {
                        display: flex;
                        // border:1px solid rgb(83, 80, 80);
                        align-items: center;
                        margin-left: 10px;
                        margin-right: 10px;
                        // padding-left: 7px;
                        // padding-right: 7px;
                        border-radius: 5px;
                        // background-color: #ababab37;
                    }

                    &_l {
                        // border: 2px solid green;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;
                        margin-left: 10px;
                        margin-right: 10px;
                        font-size: .7rem;

                        &>div {
                            // padding: 10px;
                        }
                    }

                    &_r {
                        flex-grow: 1;
                        // border: 2px solid blue;
                        display: flex;
                        flex-direction: column;
                        // align-items: center;
                        justify-content: space-around;

                        &>div {
                            display: flex;
                            align-items: center;

                            // border: 1px solid black;
                            // padding: 10px;
                            &>input {
                                font-size: .7rem;
                                border: 1px solid #ababab;
                                border-radius: 5px;
                                padding: 7px;
                                margin-top: 5px;
                                margin-bottom: 5px;
                                margin-left: 5px;
                                width: calc(100% - 30px);
                            }

                            &>img {
                                &:hover {
                                    cursor: pointer;
                                    background-color: #95cf856e;
                                    border-radius: 3px;
                                }
                            }
                        }
                    }

                    &_b {
                        display: flex;
                        justify-content: flex-end;
                        padding: 5px 5px 10px 5px;

                        &>div {
                            // border:none;
                            color: white;
                            padding: 8px 10px;
                            text-align: center;
                            // text-decoration: none;
                            // display: inline-block;
                            font-size: .7rem;
                            margin: 4px 2px;
                            // cursor: pointer;
                            font-weight: bold;
                            border-radius: 5px;

                            &.c {
                                border: 2px solid rgb(160, 71, 99);
                                background-color: rgb(160, 71, 99);

                                &:hover {
                                    background-color: rgba(160, 71, 99, 0.8);
                                    cursor: pointer;
                                }
                            }

                            &.l {
                                // border: 2px solid rgba(33, 46, 68, 1);
                                // background-color: rgba(33, 46, 68, 1);
                                background-color: $btn_blue_color;
                                margin-right: 20px;
                                padding-left: 20px;
                                padding-right: 20px;
                                padding-top: 8px;
                                padding-bottom: 8px;

                                &:hover {
                                    // background-color: rgba(33, 46, 68, 0.8);
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }

        @media only screen and (max-width: 500px) {
            width: 70%;
            margin-inline: auto;

            &_b {
                & .form {
                    &_f {
                        &_r {
                            &>div {
                                width: 90%;

                                &>img {
                                    width: 30px;

                                    &:hover {
                                        cursor: pointer;
                                        background-color: #95cf856e;
                                        border-radius: 3px;

                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        @media only screen and (max-width: 425px) {
            // width: 80%;
            margin-inline: auto;

            &_b {
                & .form {
                    &_f {
                        &_r {
                            &>div {
                                width: 80%;
                            }
                        }
                    }
                }
            }
        }
    }

    &_api {
        width: 100%;
        border: 1px solid black;

    }
}

.digital_clock {
    // position: fixed;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    border: 2px solid white;
    // right: 0;
    margin: 10px 20px;

    .days {
        font-size: 1.0rem;
        color: black;
        font-weight: bold;
        text-align: center;
        // backdrop-filter: blur(20px);
    }

    .time {
        // border:1px solid red;
        display: flex;
        padding: 5x 10px 10px 10px;
        margin-bottom: 5px;

        div {
            // border:1px solid black;
            font-size: 1.3em;
            position: relative;
            width: 50px;
            text-align: center;
            font-weight: bold;
            color: hsl(0, 0%, 0%);
            // border: 2px solid red;
            // left: 18px;

            &:nth-child(1)::after,
            &:nth-child(2)::after {
                content: ':';
                position: absolute;
                right: -4px;

            }

            &:nth-child(4) {
                font-size: 1.3em;
                left: -9px;

            }

            &:nth-child(2)::after {
                animation: animate 1s steps(1) infinite;
            }

        }
    }

}

@keyframes animate {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}