@import "../../../assets/scss/common.scss";

.container {
    font-size: .5rem;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    &>div {
        color: white;
        font-size: 1.0rem;
        border: 1px solid red;
        padding: 5px;
        border-radius: 5px;
        background-color: rgba(255, 0, 0, 0.8);
        transition: all ease 0.5s;

        &:hover {
            cursor: pointer;
            background-color: rgb(140, 66, 66);
        }
    }
}

.pipcontainer {
    background-color: rgb(61, 61, 61);
    width: 100%;
    height: 100vh;
    font-family: $amanin_font_family;
    font-size: .75rem;
    overflow-y: auto;

    &_title {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: rgb(255, 255, 255);

        &>img {
            width: 120px;
            background-color: rgba(255, 255, 255, 1);
            padding: 3px;
            // margin:3px;
            border-radius: 5px;
        }

        &>div {
            // text-align: center;
            margin: 2px 5px 0px 5px;
            background-color: rgba(255, 255, 255, 0.2);
            padding: 2px;
            border-radius: 3px;
            display: flex;
            flex-direction: row;
            align-items: center;

            &>img {
                width: 30px;
                margin: 5px;
            }

            &.gofocus {
                transition: all ease .5s;

                &:hover {
                    cursor: pointer;
                    background-color: rgba(255, 255, 255, 0.1);
                }
            }

            &.newmessage {
                padding: 5px;
                border: 2px solid red;
                background-color: rgb(129, 46, 46);
                transition: all ease .5s;

                &:hover {
                    cursor: pointer;
                    background-color: rgb(174, 74, 74);
                }

                &>div {
                    display: flex;
                    flex-direction: column;

                    &>span {
                        &.title {
                            font-size: 1.0rem;
                            font-weight: bold;
                        }

                        &.subtitle {
                            font-size: .65rem;
                        }
                    }
                }
            }

            &.logo {
                background-color: rgba(255, 255, 255, 0.8);
                // border:1px solid red;
                padding: 0px;

                &>img {
                    height: 50px;
                    width: 100%;
                    object-fit: contain;
                    margin: 0px !important;
                    padding: 0px;
                }
            }

            &.notification {
                display: flex;
                flex-direction: column;
                width: calc(100% - 12px);
                flex-grow: 1;
                // border:1px solid red;

                &>div.title {
                    font-weight: bold;
                    background-color: rgb(119, 28, 28);
                    // border:1px solid blue;
                    width: calc(100% - 5px);
                    text-align: center;
                    padding: 3px;
                    margin: 2px;
                }

                &>div.content {
                    text-align: justify;
                    margin: 2px;
                }
            }
        }
    }

    &_profile {
        display: flex;
        flex-direction: column;
        background-color: white;
        margin-top: 3px;
    }
}